import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-translate';
import objectPath from 'object-path';
import XLSX from 'xlsx';
import JSZip from 'jszip';
import queue from 'queue';
import printJS from 'print-js';
import { IconButton, Button, Tooltip, CircularProgress } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import KeyIcon from '@mui/icons-material/VpnKey';
import DataTable from 'components/DataTable';
import TimeLabel from 'components/Label/Time';
import { ChangeEvent } from 'components/JsonSchema';
import { requestRegisterKeyRecords } from 'application/actions/registry';
import evalate from 'helpers/evalate';
import downloadBase64Attach from 'helpers/downloadBase64Attach';
import { humanDateTimeFormat } from 'helpers/humanDateFormat';
import DownloadReport from './DownloadReport';
import reportToXlsx from './helpers/reportToXlsx';

const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
};
const NazkReportList = ({
    t,
    value,
    hidden,
    params = {},
    partyParams = {},
    actions,
    onChange,
    userUnits,
    rootDocument,
    updateReportData,
    withParentParty,
    partyRegistryKey,
    reportRegistryKey,
    politPartyUnitIdKey,
    parentPartyRegistryKey,
    additionalFilter,
    disablePartyUnitIdFilter,
    reportName,
    reportIsCvk
}) => {
    const [data, setData] = React.useState();
    const [busy, setBusy] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [loadingPdf, setLoadingPdf] = React.useState(false);
    const [sort, setSort] = React.useState({});
    const [rowsSelected, onRowsSelect] = React.useState([]);
    
    const setTempId = (item) => JSON.stringify(item);
    const setZipName = () => {
        const headerInfo = rootDocument?.data?.dataForHeader;
        return `${headerInfo?.period}_${headerInfo?.year}`.replace(/ /gi, '_');
    };

    const getSignerInfo = (report) => {
        const printData = [
            {
                info: t('Info')
            },
            {
                info: t('FileTitle') + ' ' + report.PDFfile.name
            },
            {
                info: t('Person') + ' ' + report.signerFullName
            },
            {
                info: t('DateTime') + ' ' + humanDateTimeFormat(report.date)
            },
            {
                info: t('DocumentId') + ' ' + report.documentId
            }
        ];

        printJS({
            printable: printData,
            properties: ['info'],
            type: 'json',
            gridHeaderStyle: 'opacity: 0;',
            gridStyle: 'border: none;',
            documentTitle: ''
        });
    };

    const handleDownloadPdf = async(list) => {
        const listToDownload = list.filter(({ id }) => rowsSelected.includes(id));

        setLoadingPdf(true);

        const pdfQueue = queue({ autostart: true, concurrency: 1 });
        
        const zip = JSZip();

        listToDownload.forEach(({
            name,
            report
        }) => pdfQueue.push(async () => {
            const url = report?.PDFfile?.url;
            
            if (!url) return;
            
            const pdfFile = await fetch(url).then(responce => responce.arrayBuffer());
            
            const blob = new Blob([pdfFile], { type: 'application/octet-stream' });
            
            zip.file(`${name}.pdf`, blob, {
                compression: 'DEFLATE',
                compressionOptions: {
                    level: 9
                }
            });
        }));

        pdfQueue.on('end', async () => {
            if (Object.keys(zip.files).length) {
                const zipFile = await zip.generateAsync({ type: 'blob' });

                downloadBase64Attach({
                    fileName: `pdf_archive_${setZipName()}`
                }, zipFile);
            }

            setLoadingPdf(false);
        });
    };

    const handleDownloadXLSX = (list) => {
        const listToDownload = list.filter(({ id }) => rowsSelected.includes(id));

        setLoading(true);

        const xlsxQueue = queue({ autostart: true, concurrency: 1 });

        const zip = JSZip();
    
        listToDownload.forEach(async ({
            report
        }) => xlsxQueue.push(async () => {
            if (!report) return;

            const responseReport = await reportToXlsx(report, false);

            if (!responseReport?.workbook) return;

            const wbout = XLSX.write(responseReport?.workbook, { bookType: 'xlsx', type: 'binary' });
            const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
            zip.file(`${responseReport?.translite}.xlsx`, blob, {
                compression: 'DEFLATE',
                compressionOptions: {
                    level: 9
                }
            });
        }));

        xlsxQueue.on('end', async () => {
            if (Object.keys(zip.files).length) {
                const zipFile = await zip.generateAsync({ type: 'blob' });

                downloadBase64Attach({
                    fileName: `xlsx_archive_${setZipName()}`
                }, zipFile);
            }

            setLoading(false);
        });
    };

    const onColumnSortChange = (columnId, order) => setSort({ [columnId]: order });

    const getAdditionalParams = React.useCallback(paramsList => Object.keys(paramsList).reduce((acc, param) => ({
        ...acc,
        [param]: objectPath.get(rootDocument.data, paramsList[param])
    }), {}), [rootDocument.data]);

    const getPartyList = React.useCallback(async () => {
        const additionalPartyParams = getAdditionalParams(partyParams);
        const partyList = [];
        let parties;

        if (politPartyUnitIdKey) {
            parties = await Promise.all([
                actions.requestRegisterKeyRecords(partyRegistryKey, {
                    data: { ...additionalPartyParams, ...getAdditionalParams({ politPartyUnitId: politPartyUnitIdKey }) },
                    limit: 30000
                })
            ]);
        } else {
            const userHeadUnits = userUnits.filter(({ head }) => head);
            const politPartyUnitIds = userHeadUnits.map(({ id }) => id).filter(Boolean);

            parties = await Promise.all(politPartyUnitIds.map(id => actions.requestRegisterKeyRecords(partyRegistryKey, {
                data: { politPartyUnitId: id, ...additionalPartyParams },
                limit: 30000
            })));

            const partyList = [];

            if (withParentParty) {
                const parentPartyIndex = parties.findIndex(({ length }) => length);
                const parentUnit = userHeadUnits[parentPartyIndex];
                if (parentUnit) {
                    const parentParty = await actions.requestRegisterKeyRecords(parentPartyRegistryKey, {
                        data: { unitId: parentUnit.id, ...additionalPartyParams },
                        limit: 30000
                    });
                    partyList.push(parentParty.shift());
                }
            }
        }

        return partyList.concat(...parties).map(({ data }) => data);
    }, [actions, getAdditionalParams, parentPartyRegistryKey, partyParams, partyRegistryKey, politPartyUnitIdKey, userUnits, withParentParty]);

    const updatelist = async () => {
        setBusy(true);
        onChange(null);

        const additionalParams = getAdditionalParams(params);
        let partyList = null;
        let withReports = null;

        if (!disablePartyUnitIdFilter) {
            partyList = await getPartyList();
            withReports = await Promise.all(partyList.map(async (party) => {
                const reports = await actions.requestRegisterKeyRecords(reportRegistryKey, {
                    data: { partyCode: party.code, ...additionalParams },
                    limit: 30000
                });
    
                const report = reports
                    .filter(record => additionalFilter ? evalate(additionalFilter, record, rootDocument.data) : true)
                    .map(({ id, data }) => ({ ...data, id }))
                    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
                    .shift();

                return { ...party, report };
            }));
        } else {
            withReports = await (async () => {
                const reports = await actions.requestRegisterKeyRecords(reportRegistryKey, {
                    data: { ...additionalParams }
                });
    
                const report = reports
                    .filter(record => additionalFilter ? evalate(additionalFilter, record, rootDocument.data) : true)
                    .map(({ data }) => data)
                    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
                    .shift();
                return [{ 
                    ...reports,
                    name: reportName ? evalate(reportName, rootDocument.data): report.name,
                    report
                }];
            })();
        };
       
        onChange(new ChangeEvent(withReports, false, true));
        setData(withReports);
        setBusy(false);
    };

    React.useEffect(() => {
        if (busy || rootDocument.isFinal || data) return;
        updatelist();
    });

    const sortKey = Object.keys(sort).shift();
    const sortDirection = sort[sortKey];
    const tableData = value && sortKey
        ? value.sort((a, b) => (sortDirection === 'desc' ? 1 : -1) * a[sortKey].localeCompare(b[sortKey]))
        : (value || (busy ? value : []));

    const list = tableData && tableData.map((item) => ({
        ...item,
        id: setTempId(item)
    }));

    return hidden ? null : (
        <DataTable
            actions={{ load: updatelist, onColumnSortChange, onRowsSelect }}
            data={list}
            sort={sort}
            rowsSelected={rowsSelected}
            CustomToolbar={() => (
                <>
                    {
                        updateReportData ? (
                            <Tooltip title={t('reload')}>
                                <IconButton disabled={busy} onClick={updatelist} size="large">
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>
                        ) : null
                    }
                    {
                        rowsSelected.length ? (
                            <>
                                <Tooltip title={t('downLoadPdf')}>
                                    <IconButton
                                        disabled={loadingPdf}
                                        onClick={() => handleDownloadPdf(list)}
                                        size="large">
                                        {loadingPdf ? <CircularProgress size={20} /> : <PictureAsPdfIcon />}
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={t('downLoadXLSX')}>
                                    <Button
                                        disabled={loading}
                                        onClick={() => handleDownloadXLSX(list)}
                                    >
                                        {loading ? <CircularProgress size={20} style={{ marginRight: 5 }} /> : null}
                                        {' XLSX'}
                                    </Button>
                                </Tooltip>
                            </>
                        ) : null
                    }
                </>
            )}
            controls={{
                pagination: false,
                toolbar: true,
                search: false,
                header: true,
                refresh: false,
                switchView: false,
            }}
            checkable={true}
            columns={[
                {
                    id: 'name',
                    sortable: true,
                    name: t('PartyName'),
                    render: (key, { name }) => name
                }, {
                    align: 'center',
                    id: 'reportData',
                    name: t('ReportDate'),
                    render: (key, { report }) => report ? <TimeLabel date={report.date} /> : t('NoReportDate')
                }, {
                    id: 'actions',
                    name: t('Download'),
                    align: 'center',
                    render: (key, { report }) => <DownloadReport report={report} reportIsCvk={reportIsCvk} />
                }, {
                    align: 'center',
                    id: 'signerInfo',
                    render: (key, { report }) => {
                        if (!report?.signerFullName) return null;

                        return (
                            <Tooltip title={t('signerInfo')}>
                                <IconButton disabled={busy} onClick={() => getSignerInfo(report)} size="large">
                                    <KeyIcon />
                                </IconButton>
                            </Tooltip>
                        );
                    }
                }
            ]}
        />
    );
}

const mapState = ({ auth: { userUnits } }) => ({ userUnits });

const mapDispatch = dispatch => ({
    actions: {
        requestRegisterKeyRecords: bindActionCreators(requestRegisterKeyRecords, dispatch)
    }
});

const translated = translate('NazkReportList')(NazkReportList);
export default connect(mapState, mapDispatch)(translated);
