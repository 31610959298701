/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import evaluate from "helpers/evaluate";
import { FormControl, FormHelperText, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { DrawerContent, Content } from "layouts/LeftSidebar";
import { EJVError, SchemaForm, SchemaStepper } from "components/JsonSchema";
import Scrollbar from "components/Scrollbar";
import Actions from "modules/tasks/pages/Task/screens/EditScreen/components/Actions";
import ImportActions from "modules/tasks/pages/Task/screens/EditScreen/components/ImportActions";
import TaskDetails from "modules/tasks/pages/Task/components/TaskDetails";
import StoreEventError from "modules/tasks/pages/Task/components/StoreEventError";
import ExtReaderMessages from "modules/tasks/pages/Task/screens/EditScreen/components/ExtReaderMessages";
import checkAccess from "helpers/checkAccess";
import HandleTask from "modules/tasks/pages/Task/screens/EditScreen/components/HandleTask";
import Timer from 'modules/tasks/pages/Task/components/Timer';

const styles = {
  schemaTitle: {
    padding: 0,
    marginBottom: 10,
  },
  oneStepTitle: {
    paddingTop: 30,
  },
  poper: {
    fontSize: 16,
    marginBottom: 20,
    marginTop: 20,
    padding: 16,
    maxWidth: 640,
    background: "rgb(255, 244, 215)",
  },
  drawer: {
    height: "100%",
    maxHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flexGrow: 1,
    overflow: "hidden",
    paddingBottom: 20,
  },
  hideStepperTitles: {
    fontSize: 20,
  },
};

const EditScreenLayout = ({
  busy,
  processing,
  readOnly,
  task,
  tasks,
  origin,
  origins,
  classes,
  actions,
  storeEventError,
  validationErrors,
  validationPageErrors,
  setStoreEventError,
  steps,
  stepName,
  userUnits,
  activeStep,
  template,
  templates,
  handleSetStep,
  computedMatch,
  fileStorage,
  handleImport,
  handleChange,
  handleStore,
  handleNextStep,
  handlePrevStep,
  handleFinish,
  isUserUnitHead,
  task: { isEntry },
  blockForward,
  extReaderMessages: {
    pendingMessage,
    externalReaderErrors,
    triggerExternalPath,
  },
  onHandleTask,
  onCancelHandlingTask,
  totalErrors,
}) => {
  const { jsonSchema } = template;
  const { description } = jsonSchema.properties[stepName];
  const result = evaluate(description, task.document.data);
  const subtitle = result instanceof Error ? description : result;

  const { hideStepperTitles } = jsonSchema;

  const handlingButton = () => {
    const handleButton = template?.jsonSchema?.handleButton;
    const resultEvalated = evaluate(handleButton, task.document.data);
    if (resultEvalated instanceof Error) return false;
    return resultEvalated;
  };

  return (
    <DrawerContent
      disableScrolls={true}
      collapseButton={true}
      drawer={
        checkAccess({ isUnitedUser: true }, null, userUnits) && !isEntry ? (
          <TaskDetails
            task={task}
            template={template}
            isUserUnitHead={isUserUnitHead}
          />
        ) : null
      }
    >
      <div className={classes.drawer}>
        <StoreEventError
          error={storeEventError}
          onClose={() => setStoreEventError(null)}
        />
        <Scrollbar>
          <div className={classes.content}>
            <Content>
              <ImportActions
                handleImport={handleImport}
                importSchema={jsonSchema.importSchema}
              />
              {!task?.finished && handlingButton() ? (
                <HandleTask
                  meta={task.meta}
                  onHandleTask={onHandleTask}
                  onCancelHandlingTask={onCancelHandlingTask}
                  busy={busy}
                />
              ) : null}
              <SchemaStepper
                task={task}
                steps={steps}
                jsonSchema={jsonSchema}
                activeStep={activeStep}
                errors={validationPageErrors}
                handleStep={(step) => () =>
                  step < activeStep && handleSetStep(step)}
              />
                    
              {subtitle ? (
                <Typography
                  variant="h4"
                  className={classNames(classes.schemaTitle, {
                    [classes.oneStepTitle]: steps.length === 1,
                    [classes.hideStepperTitles]: hideStepperTitles,
                  })}
                >
                  {subtitle}
                </Typography>
              ) : null}

              <Timer
                task={task}
                jsonSchema={jsonSchema}
                steps={steps}
                activeStep={activeStep}
              />

              {validationPageErrors.length ? (
                <div>
                  <FormControl error={true} className={classes.root}>
                    {validationPageErrors.map((error, index) => (
                      <FormHelperText key={index}>
                        <EJVError error={error} />
                      </FormHelperText>
                    ))}
                  </FormControl>
                </div>
              ) : null}
              <SchemaForm
                task={task}
                taskId={computedMatch.params.taskId}
                schema={jsonSchema.properties[stepName]}
                stepName={stepName}
                steps={steps}
                active={!busy}
                locked={readOnly || busy || processing}
                fileStorage={fileStorage}
                actions={actions}
                activeStep={activeStep}
                readOnly={task.finished || task.deleted || readOnly}
                errors={validationErrors}
                rootDocument={task.document}
                template={template}
                originDocument={origin.document}
                value={task.document.data[stepName]}
                onChange={handleChange.bind(null, stepName)}
                handleStore={handleStore.bind(this)}
                totalErrors={totalErrors}
                externalReaderMessage={
                  <>
                    {triggerExternalPath ? (
                      <ExtReaderMessages
                        classes={{ root: classes.root, paper: classes.paper }}
                        busy={busy}
                        inControl={true}
                        pendingMessage={pendingMessage}
                        externalReaderErrors={externalReaderErrors}
                      />
                    ) : null}
                  </>
                }
              />
              {!triggerExternalPath ? (
                <ExtReaderMessages
                  classes={{ root: classes.root, paper: classes.paper }}
                  busy={busy}
                  inControl={false}
                  pendingMessage={pendingMessage}
                  externalReaderErrors={externalReaderErrors}
                />
              ) : null}
            </Content>
          </div>
        </Scrollbar>
        <Actions
          steps={steps}
          stepName={stepName}
          active={!readOnly && !busy && !processing}
          task={task}
          template={template}
          finished={task.finished}
          canCommit={!task.deleted}
          actions={{
            handleNextStep,
            handlePrevStep,
            handleFinish,
          }}
          activeStep={activeStep}
          jsonSchema={jsonSchema}
          taskId={task.id}
          tasks={tasks}
          origins={origins}
          templates={templates}
          blockForward={blockForward}
        />
      </div>
    </DrawerContent>
  );
};

EditScreenLayout.propTypes = {
  busy: PropTypes.bool.isRequired,
  task: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  tasks: PropTypes.object.isRequired,
  origin: PropTypes.object.isRequired,
  origins: PropTypes.object.isRequired,
  templates: PropTypes.object.isRequired,
  storeEventError: PropTypes.func,
  validationErrors: PropTypes.array,
  validationPageErrors: PropTypes.object,
  setStoreEventError: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  stepName: PropTypes.string.isRequired,
  activeStep: PropTypes.number.isRequired,
  template: PropTypes.object.isRequired,
  handleSetStep: PropTypes.func.isRequired,
  computedMatch: PropTypes.object.isRequired,
  fileStorage: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleStore: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  handlePrevStep: PropTypes.func.isRequired,
  handleFinish: PropTypes.func.isRequired,
  isUserUnitHead: PropTypes.bool.isRequired,
  externalReaderErrors: PropTypes.array,
  pendingMessage: PropTypes.array,
  blockForward: PropTypes.bool,
};

EditScreenLayout.defaultProps = {
  validationErrors: null,
  validationPageErrors: null,
  externalReaderErrors: null,
  pendingMessage: null,
  blockForward: false,
  storeEventError: () => null,
};

export default withStyles(styles)(EditScreenLayout);
