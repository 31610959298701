import React from 'react';

import logo from 'assets/img/imageNazk.svg';

import LeftSidebarLogo from 'application/modules/common/components/LeftSidebarLogo';

export default {
    navigation: [{
        priority: 100,
        Component: () => <LeftSidebarLogo logo={logo}/>
    }]
};
