import React from 'react';

import ColorBox from 'modules/analytics/components/ColorBox';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import LightTooltip from 'modules/analytics/pages/RiskyOperations/components/LightTooltip';

const DifferentValues = ({ value, values, color, minimumToShow = 2 }) => {
    if (!values.length) {
        return value || null;
    }

    const first = values[0];

    const valueContainer = (
        <div style={{ backgroundColor: color }}>
            {first.value || '-'}
        </div>
    );

    return values.length > (minimumToShow - 1) ? (
        <LightTooltip
            title={(
                <List dense={true}>
                    {values.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                <ColorBox color={color} />
                            </ListItemIcon>
                            <ListItemText
                                primary={item.value}
                                secondary={item.label}
                            />
                        </ListItem>
                    ))}
                </List>
            )}
        >
            {valueContainer}
        </LightTooltip>
    ) : valueContainer;
};

export default DifferentValues;
