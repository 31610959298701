import React from 'react';
import { translate } from 'react-translate';

import Select from 'components/Select';
import endPoint from 'components/PartySelect/endPoint';

// import waiter from 'helpers/waitForAction';
import useTable from 'services/dataTable/useTable';

const toOption = t => (option = {}) => {
    const { id: value, data: { code, name } = {} } = option;
    return { ...option, label: t('EDRPOUCode', { code, name }), value };
};

const OfficeSelect = ({ t, busy, value, party, onChange }) => {
    const { data: { unitId } = {} } = party || {};

    const {
        data,
        filters,
        loading,
        actions,
        filters: { data_like: { politPartyUnitId } = {} }
    } = useTable(endPoint, { page: 0, rowsPerPage: 200, filters: { keyId: 7 } });

    React.useEffect(() => {
        if (unitId !== politPartyUnitId) {
            actions.onFilterChange({ ...filters, data_like: { politPartyUnitId: unitId } });
        }
    }, [actions, filters, unitId, politPartyUnitId]);

    React.useEffect(() => {
        if (!data) {
            return;
        }
        if (!unitId || (value && !data.find(({ id }) => id === value.id))) {
            onChange(null);
        }
    }, [data, onChange, unitId, value]);

    return (
        <Select
            value={value}
            options={(data || []).map(toOption(t))}
            onChange={newValue => onChange(newValue && newValue.value ? newValue : null)}
            description={t('OfficeSelect')}
            isLoading={loading}
            readOnly={busy || !unitId}
            onChangePage={(e, page) => actions.onChangePage(page)}
            // onInputChange={newSearch => waiter.addAction('OfficeSelect', () => !value && actions.onSearchChange(newSearch), 1000)}
            usePagination={false}
        />
    );
};

export default translate('Analytics')(OfficeSelect);