import React from 'react';
import { connect } from 'react-redux';

import Select from 'components/Select';
import { ChangeEvent } from 'components/JsonSchema';
import FieldLabel from 'components/JsonSchema/components/FieldLabel';
import ElementContainer from 'components/JsonSchema/components/ElementContainer';
import userHeadUnitList from 'helpers/userHeadUnitList';

const optionsToMenu = (option = {}) => {
    const { id, name, menuConfig, head, data, ...rest } = option;
    return ({
        ...rest,
        id,
        name,
        value: option.id,
        label: option.name
    });
};

const NazkPartySelect = ({
    value,
    path,
    basedOn,
    userUnits,
    sample,
    description,
    required,
    readOnly,
    error,
    width,
    noMargin,
    onChange,
    hidden
}) => {
    if (hidden) {
        return null;
    }

    const unitList = userHeadUnitList(userUnits)
        .filter(
            ({ basedOn: unitBasedOn }) => 
                !basedOn || [].concat(basedOn).some(element => unitBasedOn.includes(element))
        )
        .map(optionsToMenu);

    return (
        <ElementContainer
            sample={sample}
            required={required}
            error={error}
            bottomSample={true}
            width={width}
            noMargin={noMargin}
        >
            <Select
                readOnly={readOnly}
                description={<FieldLabel description={description} required={required} />}
                value={value}
                error={error}
                id={path.join('-')}
                multiple={false}
                onChange={newValue => onChange(new ChangeEvent(newValue, true))}
                options={unitList}
            />
        </ElementContainer>
    );
}

const mapState = ({ auth: { userUnits } }) => ({
    userUnits
});

export default connect(mapState)(NazkPartySelect);
