import React from 'react';
import { connect } from 'react-redux';

import { Button } from '@mui/material';
import { translate } from 'react-translate';

import GetAppIcon from '@mui/icons-material/GetApp';

import * as api from 'services/api';
import downloadFile from 'helpers/downloadFile';
import ErrorDialog from 'components/ConfirmDialog';

const DownloadRiskyOperation = ({ t, actions, reportId }) => {
    const [disabled, setDisabled] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [openErrorDialog, setOpenErrorDialog] = React.useState(false);

    const handleClick = React.useCallback(async () => {
        if (disabled) {
            return;
        }

        setDisabled(true);
        try {
            const content = await actions.downloadRiskyOperation(reportId);
            if (!content || !Array.isArray(content)) {
                throw new Error('NoContent');
            }

            const htmlContent = content.map(({response_html: responseHtml}) => responseHtml).filter(Boolean).join('\n');
            downloadFile(t('RiskyOperationFileName'), htmlContent);
        } catch (e) {
            setError(e);
            setOpenErrorDialog(true);
        }

        setDisabled(false);
    }, [actions, disabled, reportId, t]);

    return (
        <>
            <Button
                variant="outlined"
                disabled={disabled}
                style={{ marginTop: 20 }}
                startIcon={<GetAppIcon />}
                onClick={handleClick}
            >
                {t('DownloadRiskyOperation')}
            </Button>
            <ErrorDialog
                open={error && openErrorDialog}
                title={t('Warning')}
                cancelButtonText={t('Close')}
                description={error && t(error.message)}
                handleClose={() => setOpenErrorDialog(false)}
            />
        </>
    );
};

const mapDispatch = dispatch => ({
    actions: {
        downloadRiskyOperation: reportId => api.get(`custom/nazk/reports/${reportId}`, 'DOWNLOAD_RISKY_OPERATION', dispatch, { reportId })
    }
});

const translated = translate('Analytics')(DownloadRiskyOperation);
export default connect(null, mapDispatch)(translated);
