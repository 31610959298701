import React from 'react';
import { translate } from 'react-translate';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import LeftSidebarLayout, { Content } from 'layouts/LeftSidebar';

import DataTable from 'components/DataTable';
import useTable from 'services/dataTable/useTable';

import asModulePage from 'hooks/asModulePage';
import urlHashParams from 'helpers/urlHashParams';

import StringFilterHandler from 'components/DataTable/components/StringFilterHandler';

import endPoint from './endPoint';

const LeaderListPageCVK = ({
    t,
    title,
    location
}) => {
    const regionalCVK = location.pathname === '/users/leaders/regioncvk';
    const defaultKey = regionalCVK ? '2' : '16';
    const [keyId, setKeyId] = React.useState(defaultKey);
    const tableData = useTable(endPoint, {
        filters: {
            ...urlHashParams(),
            keyId
        }
    });

    React.useEffect(() => {
        tableData.actions.onFilterChange({ keyId});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keyId]);

    return (
        <LeftSidebarLayout location={location} title={t(title)} loading={tableData.loading}>
            {!regionalCVK ? (
                <Content small>
                    <RadioGroup
                        aria-label={'Type'}
                        value={keyId}
                        onChange={(e) => setKeyId(e.target.value)}
                    >
                        <FormControlLabel value="16" control={<Radio />} label={t('CVK')} />
                        <FormControlLabel value="2" control={<Radio />} label={t('RegionalCVK')} />
                    </RadioGroup>
                </Content>
            ) : null}
            <DataTable
                controls={{
                    pagination: true,
                    toolbar: true,
                    search: true,
                    header: true,
                    refresh: true,
                    customizateColumns: true
                }}
                columns={[{
                    id: 'name',
                    name: t('Name'),
                    render: (value, { data: { name } }) => (name || '-')
                }, {
                    id: 'code',
                    name: t('Code'),
                    render: (value, { data: { code } }) => (code || '-')
                }, {
                    id: 'lastName',
                    name: t('LastName'),
                    render: (value, { data: { lastName } }) => (lastName || '-')
                }, {
                    id: 'firstName',
                    name: t('FirstName'),
                    render: (value, { data: { firstName } }) => (firstName || '-')
                }, {
                    id: 'middleName',
                    name: t('MiddleName'),
                    render: (value, { data: { middleName } }) => (middleName || '-')
                }, {
                    id: 'ipn',
                    name: t('Ipn'),
                    render: (value, { data: { ipn } }) => (ipn || '-')
                }, {
                    id: 'email',
                    name: t('Email'),
                    render: (value, { data: { email } }) => (email || '-')
                }]}
                filterHandlers={{
                    name: props => <StringFilterHandler name={t('Name')} {...props} />,
                    code: props => <StringFilterHandler name={t('Code')} {...props} />,
                    lastName: props => <StringFilterHandler name={t('LastName')} {...props} />,
                    firstName: props => <StringFilterHandler name={t('FirstName')} {...props} />,
                    middleName: props => <StringFilterHandler name={t('MiddleName')} {...props} />,
                    ipn: props => <StringFilterHandler name={t('Ipn')} {...props} />
                }}
                {...tableData}
            />
        </LeftSidebarLayout>
    );
};

const translated = translate('UserLeaderList')(LeaderListPageCVK);
export default asModulePage(translated);
