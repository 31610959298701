import React from 'react';
import { Link } from '@mui/material';
import ExportToExcel from './ExportToExcel';

const DownloadReport = ({ report, reportIsCvk }) => {
    if (!report) {
        return '-';
    }

    const url = report.PDFfile && report.PDFfile.url;

    return <>
        <Link href={url} underline="hover">PDF</Link>
        <ExportToExcel report={report} reportIsCvk={reportIsCvk}/>
    </>;
};

export default DownloadReport;
