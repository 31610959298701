export default  t => ({
    columns: [{
        name: 'createdBy',
        title: t('CreatedBy'),
        sortingEnabled: true
    }, {
        name: 'createdAt',
        title: t('CreatedAt'),
        sortingEnabled: true
    }],
    tableColumnExtensions: [
        { columnName: 'createdBy', align: 'right' },
        { columnName: 'createdAt', align: 'right' }
    ],
    columnWidths: [
        { columnName: 'data', width: 640 },
        { columnName: 'createdBy', width: 240 },
        { columnName: 'createdAt', width: 240 }
    ],
    columnOrder: ['data', 'createdBy', 'createdAt'],
    hiddenColumns: ['createdBy', 'createdAt'],
    customColumns: []
});