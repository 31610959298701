import React from 'react';

import { Chip } from '@mui/material';

import TimeLabel from 'components/Label/Time';

import TableRow from '../components/TableRow';

const colors = {
    1: '#3a9ae6',
    2: '#60b52a',
    3: '#bf3229',
    null: '#5a5a5a'
};

const columns = (t, { is_draft }) => [{
    id: 'workflow.number',
    name: t('WorkflowNumber'),
    align: 'left',
    render: (value, { number }) => number
}, {
    id: 'workflowTemplate.name',
    align: 'left',
    sortable: 'true',
    name: t('WorkflowName'),
    render: (value, item) => <TableRow item={item} />
}, {
    id: 'workflowStatusId',
    name: t('LastStepLabel'),
    align: 'left',
    sortable: 'true',
    padding: '10',
    render: (value, { entryTaskFinishedAt, lastStepLabel, workflowStatusId }) => (
        <Chip
            style={{ cursor: 'inherit', backgroundColor: colors[workflowStatusId], color: 'white' }}
            label={entryTaskFinishedAt ? (lastStepLabel || t('NoStatus')) : t('DraftStatus')}
        />
    )
}, (is_draft
    ? {
        id: 'documents.updatedAt',
        name: t('UpdatedAt'),
        align: 'right',
        sortable: 'true',
        render: (value, { entryTask }) => {
            if (entryTask?.document?.updatedAt) {
              return <TimeLabel date={entryTask?.document?.updatedAt} />;
            }
            return null;
        }
    }
    : {
        id: 'tasks.finishedAt',
        name: t('OrderedAt'),
        align: 'right',
        sortable: 'true',
        render: (value, { entryTaskFinishedAt, createdAt }) => <TimeLabel date={entryTaskFinishedAt || createdAt} />
    })];

export default ({ t, filters }) => ({
    controls: {
        pagination: true,
        toolbar: true,
        search: true,
        header: true,
        refresh: true,
        customizateColumns: true
    },
    checkable: true,
    columns: columns(t, filters)
});
