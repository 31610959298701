export default {
    '0.1-0.5': {
        B2: 'reportNumber',
        B3: 'initiator',
        B4: 'date',
        B5: 'documentId',
        B6: 'typeElectionNext',
        B7: 'typeElectionRepeated',
        B8: 'typeElectionExtraordinary',
        B9: 'typeElectionIntermediate',
        B10: 'typeElectionAdditional',
        B11: 'typeElectionFirst',
        B12: 'isCVK',
        B13: 'presidentialElections',
        B14: 'peopleDeputyElections',
        B15: 'isTVK',
        B16: 'isDeputiesVrOfCrimea',
        B17: 'isDeputiesRegionalCouncil',
        B18: 'isDeputiesDistrictCouncil',
        B19: 'isDeputiesDistrictCityCouncil',
        B20: 'isDeputiesCityCouncil',
        B21: 'isDeputiesPositionCityHead',
        B22: 'electionRegion',
        B23: 'electionDistrict',
        B24: 'electionCity',
        B25: 'electionDate',
        B26: 'typeReportIntermediate',
        B27: 'typeReportFinal',
        B28: 'isCandidatePresidentialElections',
        B29: 'isCandidateDeputyElections',
        B30: 'isCandidateDeputiesVrOfCrimea',
        B31: 'isCandidateDeputiesRegionalCouncil',
        B32: 'isCandidateDeputiesDistrictCouncil',
        B33: 'isCandidateDeputiesDistrictCityCouncil',
        B34: 'isCandidateDeputiesCityCouncil',
        B35: 'isCandidateDeputiesTownshipCouncil',
        B36: 'isCandidateDeputiesVillageCouncil',
        B37: 'isCandidateDeputiesPositionCityHead',
        B38: 'isCandidateDeputiesPositionTownshipHead',
        B39: 'isCandidateDeputiesPositionVillageHead',
        B40: 'candidatePoliticalParty',
        B41: 'candidateRegionaOrganiztionParty',
        B42: 'periodReport',
        B43: 'candidateIndividual',
        B44: 'candidateLegalEntity',
        B45: 'candidateWithoutLegalEntity',
        B46: 'politicalPartyLegalEntityName',
        B47: 'politicalPartyLegalEntityId',
        B48: 'candidateIndividualLastName',
        B49: 'candidateIndividualtNameFirstName',
        B50: 'candidateIndividualNameMiddleName',
        B51: 'candidateIndividualNameIpn',
        B52: 'candidateIndividualNameUnzr',
        B53: 'candidateIndividualNameDateOfBirthDay',
        B54: 'isPassportCandidateIndividual',
        B55: 'isIdCardCandidateIndividual',
        B56: 'candidateIndividualPassportSerie',
        B57: 'candidateIndividualPassportNumber',
        B58: 'candidateLocationCountry',
        B59: 'candidateLocationIndex',
        B60: 'candidateLocationRegion',
        B61: 'candidateLocationDistrict',
        B62: 'candidateLocationCity',
        B63: 'candidateLocationStreet',
        B64: 'candidateLocationBuilding',
        B65: 'candidateLocationKorpus',
        B66: 'candidateLocationApt',
        B67: 'candidateIsLocationSame',
        B68: 'candidateLocationCountryFact',
        B69: 'candidateLocationIndexFact',
        B70: 'candidateLocationRegionFact',
        B71: 'candidateLocationDistrictFact',
        B72: 'candidateLocationCityFact',
        B73: 'candidateLocationStreetFact',
        B74: 'candidateLocationBuildingFact',
        B75: 'candidateLocationKorpusFact',
        B76: 'candidateLocationAptFact'
    }
};
