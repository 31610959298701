import React from 'react';
import PropTypes from 'prop-types';

import LeftSidebarLayout from 'layouts/LeftSidebar';
import InboxFileDetails from 'modules/inbox/pages/InboxFiles/components/InboxFileDetails';

const InboxFileLayout = ({ location, title, loading, inboxFile }) => (
    <LeftSidebarLayout
        location={location}
        title={title}
        loading={loading}
    >
        <InboxFileDetails {...inboxFile} />
    </LeftSidebarLayout>
);

InboxFileLayout.propTypes = {
    location: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    inboxFile: PropTypes.object
};

InboxFileLayout.defaultProps = {
    loading: false,
    inboxFile: null
};

export default InboxFileLayout;
