import objectPath from 'object-path';
import { objectArrayUnique } from 'helpers/arrayUnique';
import findObject from 'modules/analytics/pages/RiskyOperations/helpers/findObject';

export default (data, row, idPropertyName, columnName, value, tableDataPath) => data
    .map(item => objectPath.get(item, tableDataPath))
    .map(items => objectArrayUnique(items, idPropertyName))
    .map(items => items.find(findObject(row, idPropertyName)))
    .map(({ [columnName]: val } = {}, index) => val === undefined ? null : ({ label: data[index].label, value: val }))
    .filter(Boolean)
    // .filter(({ value: val }) => value !== val);
