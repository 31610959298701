import React from "react";
import LeftSidebarLayout from "layouts/LeftSidebar";

const Layout = ({ children, location, title, loading }) => {
  return (
    <LeftSidebarLayout location={location} title={title} loading={loading}>
      {children}
    </LeftSidebarLayout>
  );
};

export default Layout;
