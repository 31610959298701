import React from 'react';

import analyticsPage from 'modules/analytics/hooks/analyticsPage';
import withRange from 'modules/analytics/hooks/withRange';

import DataTable from 'components/DataTable';
import { Typography } from '@mui/material';

import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter';

const tableControls = {
    pagination: false,
    toolbar: true,
    search: false,
    header: false,
    refresh: false,
    switchView: false
};

const cellRenderer = (value, { key }) => (
    <>
        <Typography variant="body2" style={{ opacity: .5 }}>{key}</Typography>
        <Typography variant="body1">{value || '-'}</Typography>
    </>
);

const politPartyProperties = t => ([
    { id: 'partyName' },
    { id: 'partyCode' },
    {
        id: 'partyLocation',
        stringify: ({ partyLocationIndex, partyLocationRegion, partyLocationCity, partyLocationStreet, partyLocationBuilding, partyLocationApt }) => {
            return [partyLocationIndex, partyLocationRegion, partyLocationCity, partyLocationStreet, partyLocationBuilding && t('Building') + ' ' + partyLocationBuilding, partyLocationApt].filter(Boolean).join(', ')
        }
    }
]);

const politPartyHeadProperties = t => ([{
    id: 'headName',
    stringify: ({ headLastName, headFirstName, headMiddleName }) => [headLastName, headFirstName, headMiddleName].join(' ')
}, {
    id: 'headIpn'
}, {
    id: 'headDateOfBirth',
    stringify: ({ headDateOfBirthDay, headDateOfBirthMonth, headDateOfBirthYear }) => [headDateOfBirthDay, headDateOfBirthMonth, headDateOfBirthYear].join('.')
}, {
    id: 'headPassport',
    stringify: ({ headPassportSerie, headPassportNumber, headPassportIssurer, headIdNumber }) => [headIdNumber, [headPassportSerie, headPassportNumber].join(''), headPassportIssurer].filter(Boolean).join(' ')
}, {
    id: 'headLocation',
    stringify: ({ headLocationIndex, headLocationRegion, headLocationCity, headLocationStreet, headLocationBuilding, headLocationApt }) => {
        return [headLocationIndex, headLocationRegion, headLocationCity, headLocationStreet, headLocationBuilding && t('Building') + ' ' + headLocationBuilding, headLocationApt].filter(Boolean).join(', ')
    }
}]);

const employeeCountProperties = t => ([
    { id: 'quantityFirst' },
    { id: 'quantitySecond' },
    { id: 'quantityThird' }
]);

const cellColor = ({ range, compare }) => {
    if (compare !== undefined && range !== compare) {
        return '#e5e833';
    }
}

const PartyInfoPage = ({ t, range, compare }) => {
    if (!range) {
        return null;
    }

    const columns = [{
        id: 'range',
        render: cellRenderer,
        width: '50%'
    }];

    const optionToTableRow = property => ({
        key: t(capitalizeFirstLetter(property.id, true)),
        range: property.stringify ? property.stringify(range.data) : range.data[property.id]
    });

    const partyTableData = politPartyProperties(t).map(optionToTableRow);
    const partyHeadTableData = politPartyHeadProperties(t).map(optionToTableRow);
    const employeeCountTableData = employeeCountProperties(t).map(optionToTableRow);

    if (compare) {
        columns.push({
            id: 'compare',
            render: cellRenderer
        });

        politPartyProperties(t).forEach((property, propertyIndex) => {
            partyTableData[propertyIndex].compare = property.stringify ? property.stringify(compare.data) : compare.data[property.id]
        });

        politPartyHeadProperties(t).forEach((property, propertyIndex) => {
            partyHeadTableData[propertyIndex].compare = property.stringify ? property.stringify(compare.data) : compare.data[property.id]
        });

        employeeCountProperties(t).forEach((property, propertyIndex) => {
            employeeCountTableData[propertyIndex] = employeeCountTableData[propertyIndex] || {};
            employeeCountTableData[propertyIndex].compare = property.stringify ? property.stringify(compare.data) : compare.data[property.id]
        });
    }

    return (
        <>
            <DataTable
                cellColor={cellColor}
                CustomToolbar={() => <Typography variant="h6">{t('PolitParty')}</Typography>}
                controls={tableControls}
                columns={columns}
                data={partyTableData}
            />
            <DataTable
                cellColor={cellColor}
                CustomToolbar={() => <Typography variant="h6">{t('PolitPartyHead')}</Typography>}
                controls={tableControls}
                columns={columns}
                data={partyHeadTableData}
            />
            <DataTable
                cellColor={cellColor}
                CustomToolbar={() => <Typography variant="h6">{t('EmployeeCount')}</Typography>}
                controls={tableControls}
                columns={columns}
                data={employeeCountTableData}
            />
        </>
    );
}

const ranged = withRange(PartyInfoPage);
export default analyticsPage(ranged);
