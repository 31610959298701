const eventStyleTypes = {
  1: {
    backgroundColor: '#309d8b',
    borderColor: '#309d8b',
    textColor: '#fff',
    color: '#fff',
  },
  2: {
    backgroundColor: '#12753E',
    borderColor: '#12753E',
    textColor: '#fff',
    color: '#fff',
  },
  3: {
    backgroundColor: '#abcc62',
    borderColor: '#abcc62',
    textColor: '#000',
    color: '#000',
  },
  4: {
    backgroundColor: '#3788D8',
    borderColor: '#3788D8',
    textColor: '#fff',
    color: '#fff',
  },
  5: {
    backgroundColor: '#f78252',
    borderColor: '#f78252',
    textColor: '#000',
    color: '#000',
  },
  6: {
    backgroundColor: '#12753E',
    borderColor: '#12753E',
    textColor: '#fff',
    color: '#fff',
  },
};

export default eventStyleTypes;
