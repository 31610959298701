import XLSX from 'xlsx';
import objectPath from 'object-path';
import excelColumnName from 'excel-column-name';
import transliterate from 'helpers/transliterate';
import sheetParty from '../variables/zvit_pars.xlsx';
import sheetCVK from '../variables/zvit_pars_cvk.xlsx';
import cellValuesParty from '../variables/reportCellValues';
import reportTablesParty from '../variables/reportTables';
import cellValuesCVK from '../variables/reportCellValuesCVK';
import reportTablesCVK from '../variables/reportTablesCVK';

const concatItems = (report, source) => {
    let data = source.map(path => (objectPath.get(report, path) || []).pop());

    if (!data.length > 1) return data;

    let concating = {};

    data.forEach(item => {
        if (!item) return;
        concating = {
            ...concating,
            ...Object.assign(item)
        }
    });

    return [concating];
};

export default async (report,reportIsCvk, onlyGenerate) => {
    const sheet = reportIsCvk ? sheetCVK : sheetParty;
    const sheetData = await fetch(sheet).then(responce => responce.arrayBuffer());
    var workbook = XLSX.read(sheetData, { type: 'array', cellStyles: true });

    const reportTables = reportIsCvk ? reportTablesCVK  : reportTablesParty;
    const cellValues = reportIsCvk ? cellValuesCVK : cellValuesParty;

    Object.keys(cellValues).forEach((sheetName) => {
        Object.keys(cellValues[sheetName]).forEach((cellName) => {
            const cell = cellValues[sheetName][cellName];
            let cellValue;
            if (typeof cell === 'string') {
                cellValue = objectPath.get(report, cell);
            } else if (typeof cell === 'function') {
                cellValue = cell(report);
            }
            workbook.Sheets[sheetName][cellName] = { v: cellValue };
        });
    });

    reportTables.forEach(({ source, sheetName, startRow, startCell, columns, totalRow }) => {
        const data = Array.isArray(source) 
            ? concatItems(report, source) 
            : objectPath.get(report, source);

        if (!Array.isArray(data)) return;

        workbook.Sheets[sheetName]['!ref'] = 'A1:AX' + (data.length + startRow);

        data.forEach((row, rowIndex) => {
            columns.forEach((columnName, cellIndex) => {
                const cellName = excelColumnName.intToExcelCol(cellIndex + startCell) + (rowIndex + startRow);
                workbook.Sheets[sheetName][cellName] = { 
                    v: columnName === 'index' ? rowIndex + 1 : row[columnName] || ''
                };
            });
        });

        if (totalRow) {
            const startPosition = startCell + 1;
            const cellName = excelColumnName.intToExcelCol(startPosition) + (data.length + startRow);
            const cellValue = excelColumnName.intToExcelCol(startPosition + 1) + (data.length + startRow);
            workbook.Sheets[sheetName][cellValue] = { v: report[totalRow.value] || '' };
            workbook.Sheets[sheetName][cellName] = { v: totalRow.cellName|| '' };
        }
    });

    const fileName = (report.PDFfile && report.PDFfile.name && report.PDFfile.name.replace('.pdf', '')) || 'Чернетка звіту';
    const translite = transliterate(fileName, {
        softSign: false,
        lowerCase: true
    });

    if (onlyGenerate) {
        return {
            workbook,
            translite
        };
    }

    XLSX.writeFile(workbook, `${translite}.xlsx`, {
        cellStyles: true
    });

    console.log('workbook', workbook, report);
};
