import React from 'react';
import PropTypes from 'prop-types';

import LeftSidebarLayout from 'layouts/LeftSidebar';
import WorkflowTable from 'modules/workflow/pages/WorkflowList/components/WorkflowTable';

class WorkflowListLayout extends React.Component {
    renderContent() {
        const {
            TableToolbar,
            endPoint,
            checkable,
            handleItemClick
        } = this.props;

        return (
            <WorkflowTable
                endPoint={endPoint}
                TableToolbar={TableToolbar}
                checkable={checkable}
                handleItemClick={handleItemClick}
            />
        );
    }

    render() {
        const { location, title, loading } = this.props;
        return (
            <LeftSidebarLayout
                location={location}
                title={title}
                loading={loading}
            >
                {this.renderContent()}
            </LeftSidebarLayout>
        );
    }
}

WorkflowListLayout.propTypes = {
    templates: PropTypes.array,
    endPoint: PropTypes.object.isRequired,
    TableToolbar: PropTypes.instanceOf(React.Component),
    checkable: PropTypes.bool,
    location: PropTypes.object.isRequired,
    title: PropTypes.string,
    loading: PropTypes.bool,
    handleItemClick: PropTypes.func
};

WorkflowListLayout.defaultProps = {
    templates: null,
    TableToolbar: null,
    checkable: false,
    title: null,
    loading: false,
    handleItemClick: () => null
};

export default WorkflowListLayout;
