import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import qs from 'qs';

import { Tab, Tabs, Toolbar } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { TreeListSelect } from 'components/TreeList';

import LeftSidebarLayout, { Content } from 'layouts/LeftSidebar';
import {
  requestRegisters,
  requestRegisterKeys
} from 'application/actions/registry';

import ModulePage from 'components/ModulePage';

import recordEndPoint from 'application/endPoints/registryRecord';
import {
  load,
  onFilterChange,
  updateRecordValues,
  storeRecord,
  onRowsDelete,
  createRecord
} from 'services/dataTable/actions';

import RegistryKeyTable from './components/RegistryKeyTable';
import RegistryHistoryTable from './components/RegistryHistoryTable';

const styles = (theme) => ({
  toolbar: {
    padding: '10px 0'
  },
  button: {
    width: 192,
    marginLeft: 20
  },
  contentWrapper: {
    maxWidth: 827
  },
  selectedName: {
    fontSize: '28px',
    lineHeight: '32px',
    marginLeft: 20
  },
  selectedNameWrapper: {
    marginBottom: 20,
    marginTop: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0
    }
  },
  root: {
    margin: 0,
    marginBottom: 20
  },
  rootTab: {
    margin: 0,
    padding: 0,
    marginRight: 20
  },
  actionsWrapper: {
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      textAlign: 'center'
    }
  },
  perPageWrapper: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  perPageitem: {
    color: '#000',
    fontSize: 13,
    lineHeight: '16px',
    height: 40,
    width: 40,
    marginRight: 10
  },
  perPageitemActive: {
    border: '2px solid #000'
  },
  paginationState: {
    fontSize: 13,
    lineHeight: '16px'
  },
  paginationItems: {
    fontSize: 13,
    display: 'flex',
    marginRight: 27,
    cursor: 'pointer',
    alignItems: 'center',
    '&:last-child': {
      marginRight: 0
    },
    '& > svg': {
      width: 15,
      marginLeft: 5,
      marginRight: 5
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: 10,
      marginTop: 10,
      width: '100%',
      justifyContent: 'center'
    }
  },
  hideOnXs: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  disabled: {
    opacity: 0.5,
    cursor: 'initial',
    pointerEvents: 'none'
  },
  rotateItem: {
    transform: 'rotate(180deg)'
  },
  initialCursor: {
    cursor: 'initial'
  },
  borderBottom: {
    display: 'inline-block',
    minWidth: 25,
    textAlign: 'center',
    marginRight: 5,
    borderBottom: '2px solid #000'
  },
  lastPageValueWrapper: {
    paddingLeft: 5
  },
  exportToExelIcon: {
    transform: 'rotate(90deg)',
    color: '#000'
  },
  createButton: {
    marginLeft: 50
  },
  createNewRecordButton: {
    marginLeft: 40,
    marginRight: 0
  },
  exportToExelWrapper: {
    color: '#000',
    marginLeft: 20
  },
  progressBar: {
    marginTop: 15
  },
  tableCell: {
    paddingLeft: '0!important'
  },
  tableHeaderRow: {
    '& > th': {
      paddingLeft: '0!important'
    }
  },
  searchIcon: {
    marginBottom: 5
  },
  searchInput: {
    '& label': {
      transform: 'translate(30px, 21px) scale(1)'
    }
  },
  pageInput: {
    marginRight: 5,
    '& input': {
      textAlign: 'center',
      paddingTop: 0
    }
  }
});

class RegistryPage extends ModulePage {
  state = { selected: null, newRecord: null, activeTab: 0 };

  componentGetTitle() {
    const { t } = this.props;
    return t('title');
  }

  handleSelectKey = (selected) => {
    const { keys, history } = this.props;
    const selectedKey = selected && keys.find(({ id }) => id === selected.id);

    if (!selected) {
      this.setState({ selected: null });
      history.push('/registry');
    } else {
      this.setState({
        selected: selectedKey
      }, () => history.push(this.getFiltersFromUrl(selected)));
    }
  };

  getFiltersFromUrl = (selected) => {
    const { history } = this.props;
    const { search } = history.location;
    const { rowsPerPage, page } = qs.parse(search.replace('?', ''));
    const searchString = `/registry?keyId=${selected.id}${page ? `&page=${page}` : ''
      }${rowsPerPage ? `&rowsPerPage=${rowsPerPage}` : ''}`;
    return searchString;
  };

  setSavedFilters = () => {
    const { history } = this.props;
    const { selected } = this.state;

    const { search } = history.location;
    const displayList = this.filterItems();

    if (!displayList || !search.length || selected) return;

    const { keyId } = qs.parse(search.replace('?', ''));

    let selectedItem = null;

    displayList.forEach((firstLevel) => {
      const { id, items } = firstLevel;
      if (items) {
        items.forEach((item) => {
          const { id: itemId } = item;
          if (Number(itemId) === Number(keyId)) selectedItem = item;
        });
      }
      if (Number(id) === Number(keyId)) selectedItem = firstLevel;
    });

    if (!selectedItem) return;

    this.handleSelectKey(selectedItem);
  };

  filterItems = () => {
    const { registers, keys } = this.props;

    let items = null;

    if (registers && keys) {
      items = (registers || [])
        .map(({ id, description }) => ({
          id,
          name: description,
          items: (keys || [])
            .filter((key) => key.registerId === id)
            .map(key => ({
              id: key.id,
              name: key.name
            }))
        }))
        .map(parent => (parent.items.length === 1 ? parent.items.shift() : parent));
    }

    return items;
  };

  componentDidUpdate = () => this.setSavedFilters();

  componentDidMount = () => {
    super.componentDidMount();

    const { registers, keys, actions } = this.props;

    if (!registers) actions.requestRegisters();

    if (!keys) actions.requestRegisterKeys();
  };

  render = () => {
    const { selected, activeTab } = this.state;
    const { t, classes, location, loading, history } = this.props;

    const items = this.filterItems();

    return (
      <LeftSidebarLayout
        title={t('title')}
        location={location}
        loading={loading}
      >
        <Content>
          <Toolbar className={classes.toolbar} id="select-key">
            <TreeListSelect
              placeholder={t('SelectKey')}
              items={items}
              selected={selected}
              onSelect={this.handleSelectKey}
              id="select-key"
            />
          </Toolbar>
          {
            selected ? (
              <>
                <Tabs
                  value={activeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={(event, tab) => this.setState({ activeTab: tab })}
                  aria-label="disabled tabs example"
                >
                  <Tab label={t('Records')} />
                  {
                    selected && selected.access && selected.access.allowHistory ? (
                      <Tab label={t('History')} />
                    ) : null
                  }
                </Tabs>
                {
                  activeTab === 0 ? (
                    <RegistryKeyTable
                      disableElevation={true}
                      selectedKey={selected}
                      history={history}
                      classes={classes}
                    />
                  ) : null
                }

                {
                  activeTab === 1 ? (
                    <RegistryHistoryTable
                      disableElevation={true}
                      selectedKey={selected}
                      classes={classes}
                    />
                  ) : null
                }
              </>
            ) : null
          }
        </Content>
      </LeftSidebarLayout>
    );
  };
}

const mapStateToProps = ({
  registry: { registers, keys },
  registryRecordList: { loading, data: records }
}) => ({
  registers,
  keys,
  loading,
  records
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    load: bindActionCreators(load(recordEndPoint), dispatch),
    onFilterChange: bindActionCreators(
      onFilterChange(recordEndPoint),
      dispatch
    ),
    requestRegisters: bindActionCreators(requestRegisters, dispatch),
    requestRegisterKeys: bindActionCreators(requestRegisterKeys, dispatch),
    storeRecord: bindActionCreators(storeRecord(recordEndPoint), dispatch),
    updateRecordValues: bindActionCreators(
      updateRecordValues(recordEndPoint),
      dispatch
    ),
    onRowsDelete: bindActionCreators(onRowsDelete(recordEndPoint), dispatch),
    createRecord: bindActionCreators(createRecord(recordEndPoint), dispatch)
  }
});

const translated = translate('RegistryPage')(RegistryPage);
const styled = withStyles(styles)(translated);
export default connect(mapStateToProps, mapDispatchToProps)(styled);
