import React from 'react';
import PropTypes from 'prop-types';

import LeftSidebarLayout from 'layouts/LeftSidebar';

import Preloader from 'components/Preloader';
import FileDataTable from 'components/FileDataTable';

import Header from 'modules/workflow/pages/Workflow/components/Header';

const WorkflowLayout = ({
    location,
    title,
    actions,
    loading,
    debugTools,
    workflow,
    fileStorage
}) => (
        <LeftSidebarLayout
            location={location}
            title={title}
            loading={loading}
            debugTools={debugTools}
        >
            {loading ? <Preloader /> : (
                <>
                    <Header
                        workflow={workflow}
                        timeline={workflow.timeline || []}
                    />
                    <FileDataTable
                        data={workflow.files}
                        fileStorage={fileStorage}
                        actions={actions}
                    />
                </>
            )}
        </LeftSidebarLayout>
    );

WorkflowLayout.propTypes = {
    location: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    actions: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    debugTools: PropTypes.object,
    workflow: PropTypes.object,
    fileStorage: PropTypes.object
};

WorkflowLayout.defaultProps = {
    loading: false,
    debugTools: {},
    workflow: null,
    fileStorage: {}
};

export default WorkflowLayout;
