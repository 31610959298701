
import React from 'react';
import objectPath from 'object-path';
import DataTable from 'components/DataTable';

import asModulePage from 'hooks/asModulePage';
import withRange from 'modules/analytics/hooks/withRange';
import analyticsPage from 'modules/analytics/hooks/analyticsPage';

import useStaticTable from 'services/dataTable/useStaticTable';
import TotalCostTable from 'modules/analytics/components/TotalCostTable';
import SelectFilterHandler from 'components/DataTable/components/SelectFilterHandler';
import findObject from 'modules/analytics/pages/RiskyOperations/helpers/findObject';
import getDifferentValues from 'modules/analytics/pages/RiskyOperations/helpers/getDifferentValues';
import DifferentValues from 'modules/analytics/pages/RiskyOperations/DifferentValues';
import ExportTableToPdf from 'modules/analytics/components/ExportTableToPdf';

import arrayUnique, { objectArrayUnique } from 'helpers/arrayUnique';

const RANGE_ITEMS_COLOR = '#E5E833';
const COMPARE_ITEMS_COLOR = '#73E6FF';

const PropertyPage = ({ 
    t, 
    party,
    office,
    pageTitle,
    range,
    compare,
    tableDataPath = '',
    settings: {
        disableUniq,
        idPropertyName,
        columns = [],
        costPropertyName,
        loosePropertyName
    } = {}
}) => {
    const uniqFunc = disableUniq ? (arr) => arr : objectArrayUnique;

    const data = uniqFunc([].concat(...(range || []).map(item => objectPath.get(item, tableDataPath))), idPropertyName);
    const compareData = uniqFunc([].concat(...(compare || []).map(item => objectPath.get(item, tableDataPath))), idPropertyName);
    const tableData = uniqFunc([].concat(data, compareData), idPropertyName);

    const filterOptions = arrayUnique(tableData.filter(Boolean).map(({ [columns[0].id]: filterOption }) => filterOption).filter(Boolean))
        .map(option => ({ id: option, name: option }));

    const tableProps = useStaticTable(tableData);

    React.useEffect(() => {
        tableProps.actions.load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [range, compare]);

    const cellColor = React.useCallback(({ id: rowId, ...row } = {}) => {
        if (!compare || !range) {
            return;
        }

        const dataRow = data.find(findObject(row, idPropertyName));
        const compareRow = compareData.find(findObject(row, idPropertyName));

        if (!dataRow) {
            return COMPARE_ITEMS_COLOR;
        }

        if (!compareRow) {
            return RANGE_ITEMS_COLOR;
        }
    }, [compare, range, compareData, data, idPropertyName]);

    const renderColumn = React.useCallback((value, row, columnKey, rowIndex, columnName) => {
        if (!compare || !range) {
            return value || '-';
        }

        const dataRow = data.find(findObject(row, idPropertyName));
        const compareRow = compareData.find(findObject(row, idPropertyName));

        if (!dataRow) {
            return (
                <DifferentValues
                    minimumToShow={1}
                    value={value || '-'}
                    color={COMPARE_ITEMS_COLOR}
                    values={getDifferentValues(compare, row, idPropertyName, columnName, value, tableDataPath)}
                />
            );
        }

        if (!compareRow) {
            return (
                <DifferentValues
                    minimumToShow={1}
                    value={value || '-'}
                    color={RANGE_ITEMS_COLOR}
                    values={getDifferentValues(range, row, idPropertyName, columnName, value, tableDataPath)}
                />
            );
        }

        if (compareRow && compareRow[columnName] !== value) {
            return (
                <>
                    <DifferentValues
                        minimumToShow={1}
                        value={value || '-'}
                        color={RANGE_ITEMS_COLOR}
                        values={getDifferentValues(range, row, idPropertyName, columnName, value, tableDataPath)}

                    />
                    <DifferentValues
                        minimumToShow={1}
                        value={value || '-'}
                        color={COMPARE_ITEMS_COLOR}
                        values={getDifferentValues(compare, row, idPropertyName, columnName, value, tableDataPath)}
                    />
                </>
            );
        }

        return value || '-';
    }, [compare, compareData, data, idPropertyName, range, tableDataPath]);

    const filterHandlers = {};

    if (filterOptions.length) {
        filterHandlers[columns[0].id] = props => (
            <SelectFilterHandler
                {...props}
                name={t(columns[0].name)}
                useOwnNames={true}
                options={filterOptions}
            />
        );
    }

    return (
        <>
            <TotalCostTable
                range={range}
                compare={compare}
                rangeData={data}
                compareData={compareData}
                tableDataPath={tableDataPath}
                idPropertyName={idPropertyName}
                costPropertyName={costPropertyName}
                loosePropertyName={loosePropertyName}
            />
            <DataTable
                {...tableProps}
                tableTitle={pageTitle}
                cellColor={cellColor}
                headerCellStyle={{ backgroundColor: '#e0e0e0' }}
                columns={(columns || []).map(column => ({ ...column, name: t(column.name), render: renderColumn }))}
                CustomToolbar={() => (
                    <ExportTableToPdf
                        party={party}
                        office={office}
                        range={range}
                        compare={compare}
                        columns={columns}
                        cellColor={cellColor}
                        tableData={tableData}
                        tableTitle={pageTitle}
                        tableProps={tableProps}
                        renderColumn={renderColumn}
                    />
                )}
                controls={{
                    pagination: true,
                    toolbar: true,
                    search: true,
                    header: true,
                    refresh: false,
                    switchView: false,
                    customizateColumns: true,
                    fullscreenMode: true
                }}
                filterHandlers={filterHandlers}
            />
        </>
    );
}

const ranged = withRange(PropertyPage, { multiple: true });
const asPage = analyticsPage(ranged);
export default asModulePage(asPage);