import React from 'react';
import { translate } from 'react-translate';

import FileDataTable from 'components/FileDataTable';
import EmptyPage from 'components/EmptyPage';

import endPoint from 'application/endPoints/inboxFiles';

import dataTableConnect from 'services/dataTable/connect';
import dataTableAdapter from 'services/dataTable/adapter';

import TableToolbar from './TableToolbar';

const InboxFilesTable = (props) => {
    const {
        t,
        data,
        loading,
        filters,
        fileStorage,
        handleItemClick
    } = props;

    if (!loading && data && data.meta.total === 0 && !filters.filtered && !filters.search) {
        return (
            <EmptyPage
                title={t('EmptyListTitle')}
                description={t('EmptyListDescription')}
                id="inbox-empty-page"
            />
        );
    }

    const hightlightIds = (data || []).filter(({ isRead }) => !isRead).map(({ id }) => id);
    const tableSettings = dataTableAdapter(props);
    const tableData = Array.isArray(tableSettings.data) ? tableSettings.data.map(item => ({
        ...item,
        downloadToken: item.documentId,
        name: item.name + '.pdf'
    })) : tableSettings.data;

    return (
        <FileDataTable
            {...tableSettings}
            CustomToolbar={tableProps => <TableToolbar {...tableProps} t={t} />}
            controls={{
                pagination: true,
                toolbar: true,
                search: false,
                header: true,
                refresh: true,
                switchView: false,
                customizateColumns: true
            }}
            fileStorage={fileStorage}
            onRowClick={handleItemClick}
            hightlight={hightlightIds}
            data={tableData}
        />
    );
};

const translated = translate('InboxFilesListPage')(InboxFilesTable);
export default dataTableConnect(endPoint)(translated);
