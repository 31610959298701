import React from 'react';
import BusinessIcon from '@mui/icons-material/Business';

import UserListPage from 'application/modules/users/pages/UserList';
import LeaderListPage from 'modules/users/pages/LeaderList';
import LeaderListPageCVK from 'modules/users/pages/LeaderListPageCVK';

const access = {
    affiliateUnits: [18, 1000002, 21],
    unitHasAccessTo: [
        'navigation.users.list'
    ]
};

export default {
    routes: [
        {
            path: '/users',
            component: UserListPage,
            title: 'Users',
            access
        },
        {
            path: '/users/leaders',
            component: LeaderListPage,
            title: 'Leaders',
            access: { isUserUnitHead: [18] }
        },
        {
            path: '/users/leaders/cvk',
            component: LeaderListPageCVK,
            title: 'Leaders',
            access: { isUserUnitHead: [30] }
        },
        {
            path: '/users/leaders/regioncvk',
            component: LeaderListPageCVK,
            title: 'Leaders',
            access: { isUserUnitHead: [32] }
        }
    ],
    navigation: [{
        id: 'Users',
        title: 'Affiliate',
        icon: <BusinessIcon />,
        priority: 30,
        access,
        children: [{
            id: 'list',
            path: '/users',
            title: 'Users',
            access: { affiliateUnits: [18, 1000002, 21] }
        }, {
            id: 'leaders',
            path: '/users/leaders',
            title: 'Leaders',
            access: { isUserUnitHead: [18] }
        }, {
            id: 'leaders',
            path: '/users/leaders/cvk',
            title: 'Leaders',
            access: { isUserUnitHead: [30] }
        }, {
            id: 'leaders',
            path: '/users/leaders/regioncvk',
            title: 'Leaders',
            access: { isUserUnitHead: [32] }
        },  {
            id: 'OfficeManagement',
            children: [{
                id: 'RegionalOfficeCreate',
                title: 'RegionalOfficeCreate',
                path: '/tasks/create/101054/101054001',
                access: { userHasUnit: 18 }
            }, {
                id: 'PartySettings',
                title: 'PartySettings',
                path: '/tasks/create/101053/101053002',
                access: { userHasUnit: [18, 1000002], isUserUnitHead: [21] }
            }, {
                id: 'RegionalPartyDeactivate',
                title: 'RegionalPartyDeactivate',
                path: '/tasks/create/101055/101055001',
                access: { userHasUnit: 18 }
            }, {
                id: 'PartyCreate',
                title: 'PartyCreate',
                path: '/tasks/create/101052/101052001',
                access: { userHasUnit: 1000002 }
            }, {
                id: 'PartyDeactivate',
                title: 'PartyDeactivate',
                path: '/tasks/create/101051/101051001',
                access: { userHasUnit: 1000002 }
            }],
            access: {
                userHasUnit: [18, 1000002],
                isUserUnitHead: [21]
            }
        }, {
            id: 'OfficeManagementTVK',
            children: [{
                id: 'RegionalOfficeTVKCreate',
                title: 'RegionalOfficeTVKCreate',
                path: '/tasks/create/101116/101116001',
                access: { userHasUnit: 30 }
            }, {
                id: 'RegionalOfficeTVKSettings',
                title: 'RegionalOfficeTVKSettings',
                path: '/tasks/create/101117/101117001',
                access: { userHasUnit: 30 }
            }, {
                id: 'RegionalOfficeTVKDeactivate',
                title: 'RegionalOfficeTVKDeactivate',
                path: '/tasks/create/101119/101119001',
                access: { userHasUnit: 30 }
            }],
            access: {
                userHasUnit: [30]
            }
        }, {
            id: 'CandidateManagement',
            children: [{
                id: 'CandidateCreate',
                title: 'CandidateCreate',
                path: '/tasks/create/101109/101109001',
                access: { userHasUnit: [30,32] }
            }, {
                id: 'CandidateSettings',
                title: 'CandidateSettings',
                path: '/tasks/create/101112/101112001',
                access: { userHasUnit: [30,32]  }
            }, {
                id: 'CandidateDeactivate',
                title: 'CandidateDeactivate',
                path: '/tasks/create/101111/101111001',
                access: { userHasUnit: [30,32]  }
            }],
            access: {
                userHasUnit: [30,32]
            }
        }, {
            id: 'ManagereManagement',
            children: [{
                id: 'ManagereCreate',
                title: 'ManagereCreate',
                path: '/tasks/create/101113/101113001',
                access: { userHasUnit: [28] }
            }, {
                id: 'ManagereSettings',
                title: 'ManagereSettings',
                path: '/tasks/create/101114/101114001',
                access: { userHasUnit: [28]  }
            }, {
                id: 'ManagereDeactivate',
                title: 'ManagereDeactivate',
                path: '/tasks/create/101115/101115001',
                access: { userHasUnit: [28]  }
            }],
            access: {
                userHasUnit: [28]
            }
        }]
    }]
};
