import qs from 'qs';

export default {
    dataURL: 'registers/keys',
    sourceName: 'registryRecordList',
    sticky: false,
    staticData: false,
    searchFilterField: 'search',
    getDataUrl: (dataURL, { page = 1, rowsPerPage, filters: { keyId, search, ...filters }, sort }, useQueryParams = true) => {
        const offset = (page - 1) * rowsPerPage;
        const queryString = qs.stringify({
            offset,
            limit: rowsPerPage,
            search,
            sort,
            data_like: filters
        }, { arrayFormat: 'index' });

        return `${dataURL}/${keyId}/records` + ((useQueryParams && queryString) ? '?' + queryString : '');
    },
    mapData: (payload) => {
        const { meta } = payload;
        const { limit, count } = meta || {};

        return {
            data: payload,
            rowsPerPage: limit,
            count
        };
    }
};
