
import React from 'react';

import TimelineIcon from '@mui/icons-material/Timeline';

import PartyInfoPage from 'modules/analytics/pages/PartyInfo';
import PropertyPage from 'modules/analytics/pages/Property';
// import RegistryPage from 'modules/analytics/pages/RegistryPage';
import RiskyOperationsPage from 'modules/analytics/pages/RiskyOperations';

import transportTableSettings from 'modules/analytics/variables/settings/transport';
import realEstateTableSettings from 'modules/analytics/variables/settings/realEstate';
import movablesTableSettings from 'modules/analytics/variables/settings/movables';
import securitiesTableSettings from 'modules/analytics/variables/settings/securities';
import moneyTableSettings from 'modules/analytics/variables/settings/money';
import intangibleAssetsTableSettings from 'modules/analytics/variables/settings/intangibleAssets';

import organizationsTableSettings from 'modules/analytics/variables/settings/organizations';
import enterprisesTableSettings from 'modules/analytics/variables/settings/enterprises';
import financialLiabilitiesTableSettings from 'modules/analytics/variables/settings/financialLiabilities';
import conMoneyTableSettings from 'modules/analytics/variables/settings/conMoney';
import contributionOtherConTableSettings from 'modules/analytics/variables/settings/conTable';
import contributionCoastsTableSettings from 'modules/analytics/variables/settings/coastsTable';
import contributionOtherTableSettings from 'modules/analytics/variables/settings/contrOtherTable';
import paymentsPaymentGovTableSettings from 'modules/analytics/variables/settings/paymentsPaymentGovTable';
import paymentsOtherTableSettings from 'modules/analytics/variables/settings/paymentsOtherTable';
import paymentsCoastsTableSettings from 'modules/analytics/variables/settings/paymentsCoastsTable';
import paymentsOtherCostsTableSettings from 'modules/analytics/variables/settings/paymentsOtherCostsTable';

const access = { userHasUnit: 101000355 };

export default {
    routes: [
        {
            path: '/analytics/properties/real-estate',
            component: PropertyPage,
            title: 'RealEstate',
            tableDataPath: 'data.propertyObjects',
            settings: realEstateTableSettings,
            access
        },
        {
            path: '/analytics/properties/movables',
            component: PropertyPage,
            title: 'Movables',
            tableDataPath: 'data.propertyMovables',
            settings: movablesTableSettings,
            access
        },
        {
            path: '/analytics/properties/transport',
            component: PropertyPage,
            title: 'Transport',
            tableDataPath: 'data.propertyTransport',
            settings: transportTableSettings,
            access
        },
        {
            path: '/analytics/properties/securities',
            component: PropertyPage,
            title: 'Securities',
            tableDataPath: 'data.propertyPapers',
            settings: securitiesTableSettings,
            access
        },
        {
            path: '/analytics/properties/money',
            component: PropertyPage,
            title: 'Money',
            tableDataPath: 'data.propertyMoney',
            settings: moneyTableSettings,
            access
        },
        {
            path: '/analytics/properties/intangible-assets',
            component: PropertyPage,
            title: 'IntangibleAssets',
            tableDataPath: 'data.propertyNoMoney',
            settings: intangibleAssetsTableSettings,
            access
        },
        {
            path: '/analytics/organizations',
            component: PropertyPage,
            title: 'Organizations',
            tableDataPath: 'data.tablets1',
            settings: organizationsTableSettings,
            access
        },
        {
            path: '/analytics/enterprises',
            component: PropertyPage,
            title: 'Enterprises',
            tableDataPath: 'data.tablets2',
            settings: enterprisesTableSettings,
            access
        },
        {
            path: '/analytics/contributions/con-money',
            component: PropertyPage,
            title: 'ConMoney',
            tableDataPath: 'data.contributionConMoney',
            settings: conMoneyTableSettings,
            access
        },
        {
            path: '/analytics/contributions/other-con',
            component: PropertyPage,
            title: 'OtherCon',
            tableDataPath: 'data.contributionOtherCon',
            settings: contributionOtherConTableSettings,
            access
        },
        {
            path: '/analytics/contributions/coasts',
            component: PropertyPage,
            title: 'Coasts',
            tableDataPath: 'data.contributionCosts',
            settings: contributionCoastsTableSettings,
            access
        },
        {
            path: '/analytics/contributions/other',
            component: PropertyPage,
            title: 'Other',
            tableDataPath: 'data.contributionOtherCosts',
            settings: contributionOtherTableSettings,
            access
        },
        {
            path: '/analytics/contributions',
            redirect: true,
            to: '/analytics/contributions/con-money',
            access
        },
        {
            path: '/analytics/payments/paymentGov',
            component: PropertyPage,
            title: 'PaymentGov',
            tableDataPath: 'data.paymentGov',
            settings: paymentsPaymentGovTableSettings,
            access
        },
        {
            path: '/analytics/payments/other',
            component: PropertyPage,
            title: 'Other',
            tableDataPath: 'data.paymentOther',
            settings: paymentsOtherTableSettings,
            access
        },
        {
            path: '/analytics/payments/coasts',
            component: PropertyPage,
            title: 'Coasts',
            tableDataPath: 'data.paymentCostsPaymentReceive',
            settings: paymentsCoastsTableSettings,
            access
        },
        {
            path: '/analytics/payments/otherCosts',
            component: PropertyPage,
            title: 'Other',
            tableDataPath: 'data.paymentOtherCostsPaymentReceive',
            settings: paymentsOtherCostsTableSettings,
            access
        },
        {
            path: '/analytics/payments',
            redirect: true,
            to: '/analytics/payments/paymentGov',
            access
        },
        {
            path: '/analytics/financial-liabilities',
            component: PropertyPage,
            title: 'FinancialLiabilities',
            tableDataPath: 'data.obligate',
            settings: financialLiabilitiesTableSettings,
            access
        },
        {
            path: '/analytics/properties',
            redirect: true,
            to: '/analytics/properties/real-estate',
            access
        },
        {
            path: '/analytics/risky-operations',
            component: RiskyOperationsPage,
            title: 'RiskyOperations',
            access
        },
        {
            path: '/analytics',
            component: PartyInfoPage,
            title: 'PartyInfo',
            access
        }
    ],
    navigation: [{
        id: 'Analytics',
        title: 'Analytics',
        path: '/analytics',
        icon: <TimelineIcon />,
        priority: 20,
        access
    }]
};

