import React, { useCallback } from 'react';
import domToPdf from 'dom-to-pdf';
import { translate } from 'react-translate';

import { Dialog, DialogContent, IconButton, Tooltip, Typography } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import DataTable from 'components/DataTable';
import useStaticTable from 'services/dataTable/useStaticTable';

const ExportTableToPdf = ({
    t,
    range,
    party,
    office,
    compare,
    columns,
    cellColor,
    tableData,
    tableTitle,
    renderColumn,
    tableProps: { sort, search, filters, hiddenColumns }
}) => {
    const [printing, setPrinting] = React.useState(false);
    const exportDataTableProps = useStaticTable(tableData, { rowsPerPage: 10000, hiddenColumns });
    const { data } = exportDataTableProps;

    const tableRef = useCallback(node => {
        if (!node) {
            return;
        }

        domToPdf(node, {
            filename: t('ReportName'),
            overrideWidth: 1920
        }, () => setPrinting(false));
    }, [t]);

    const generateReport = () => {
        const { actions } = exportDataTableProps;

        actions.onFilterChange(filters, false);
        actions.onSearchChange(search, false);

        Object.keys(sort).forEach(sortKey => {
            actions.onColumnSortChange(sortKey, sort[sort]);
        });

        actions.load();
        setPrinting(true);
    };

    return data && data.length ? (
        <>
            <Tooltip title={t('ExportDataToPdf')}>
                <IconButton onClick={generateReport} size="large">
                    <PictureAsPdfIcon />
                </IconButton>
            </Tooltip>
            {printing ? (
                <Dialog
                    open={true}
                    fullScreen={true}
                >
                    <div ref={tableRef}>
                        <DialogContent>
                            <Typography variant="h3">{[tableTitle, t('AnalitycsReport')].join(' - ')}</Typography>
                            <br />
                            <Typography variant="body2" style={{ opacity: .5 }}>{t('PolitParty')}</Typography>
                            <Typography variant="body1">{party.data.name}</Typography>
                            {office ? (
                                <>
                                    <br />
                                    <Typography variant="body2" style={{ opacity: .5 }}>{t('RegionalOffice')}</Typography>
                                    <Typography variant="body1">{office.data.name}</Typography>
                                </>
                            ) : null}
                            {range ? (
                                <>
                                    <br />
                                    <Typography variant="body2" style={{ opacity: .5 }}>{t('ReportRangeSelect')}</Typography>
                                    {range.map(({ label }, key) => <Typography key={key} variant="body1">{label}</Typography>)}
                                </>
                            ) : null}
                            {compare ? (
                                <>
                                    <br />
                                    <Typography variant="body2" style={{ opacity: .5 }}>{t('ReportCompare')}</Typography>
                                    {compare.map(({ label }, key) => <Typography key={key} variant="body1">{label}</Typography>)}
                                </>
                            ) : null}
                            {Object.keys(filters).map((filterKey, index) => {
                                const filterName = columns.find(({ id }) => id === filterKey).name;

                                return (
                                    <>
                                        <br />
                                        <Typography variant="body2" style={{ opacity: .5 }}>{t(filterName)}</Typography>
                                        <Typography variant="body1">{filters[filterKey]}</Typography>
                                    </>
                                );
                            })}
                            <DataTable
                                {...exportDataTableProps}
                                cellColor={cellColor}
                                headerCellStyle={{ backgroundColor: '#e0e0e0' }}
                                columns={(columns || []).map(column => ({ ...column, name: t(column.name), render: renderColumn }))}
                                controls={{
                                    pagination: false,
                                    toolbar: false,
                                    search: false,
                                    header: true,
                                    refresh: false,
                                    switchView: false,
                                    customizateColumns: false,
                                    fullscreenMode: false
                                }}
                            />
                        </DialogContent>
                    </div>
                </Dialog>
            ) : null
            }
        </>
    ) : null;
};

export default translate('Analytics')(ExportTableToPdf);
