import React from 'react';
import { translate } from 'react-translate';

import Select from 'components/Select';
import endPoint from 'components/PartySelect/endPoint';

import useTable from 'services/dataTable/useTable';

const toOption = (option = {}) => {
    const { id: value, data: { period, year, types } = {} } = option;
    return { ...option, label: [period, year, types].join(' '), value };
};

const ReportRangeSelect = ({ t, setBusy, exclude, value, onChange, party, office, multiple }) => {
    const { data: { code: partyCode } = {} } = party || {};
    const { data: { code: officeCode } = {} } = office || {};

    const code = officeCode || partyCode;

    const {
        data,
        page,
        filters,
        search,
        loading,
        actions,
        filters: { data_like: { partyCode: filterCode } = {} }
    } = useTable(endPoint, { rowsPerPage: 10, filters: { keyId: 5 } });

    const excludeIds = [].concat(exclude).filter(Boolean).map(({ id }) => id);
    const options = loading ? null : (data && data.filter(({ id }) => !excludeIds.includes(id)).map(toOption));

    // React.useEffect(() => {
    //     setBusy && setBusy(loading);
    // }, [loading, setBusy]);

    React.useEffect(() => {
        if (code !== filterCode) {
            actions.onFilterChange({ ...filters, data_like: { partyCode: code } });
        }
    }, [actions, filters, onChange, code, filterCode]);

    React.useEffect(() => {
        if (!options) {
            return;
        }

        const valueIds = [].concat(value).filter(Boolean).map(({ id }) => id);

        if (!code || (valueIds.length && options.length && !options.find(({ id }) => valueIds.includes(id)))) {
            onChange(null);
        }
    }, [options, onChange, code, value]);

    return (
        <Select
            page={page}
            value={value}
            multiple={multiple}
            options={options}
            onChange={onChange}
            description={t('ReportRangeSelect')}
            isLoading={loading}
            readOnly={!code}
            onChangePage={actions.onChangePage}
            onInputChange={newSearch => actions.onSearchChange(newSearch, newSearch !== search)}
            usePagination={true}
            pagination={data && data.meta}
        />
    );
};

export default translate('Analytics')(ReportRangeSelect);