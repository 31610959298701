/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import RecordsTreeControl from './components/recordsTree';
import SeparatedRegister from './components/separatedRegisters';

const Address = (props) => {
    const {
        recordsTree,
        template,
        stepName,
        schema,
        withNamedObjects,
        hidden,
        cleanWhenHidden,
        rootDocument,
        actions,
        path,
        name
    } = props;

    if (recordsTree === null) {
        return <RecordsTreeControl {...props} />;
    }

    return (
        <SeparatedRegister
            recordsTree={recordsTree}
            template={template}
            stepName={stepName}
            schema={schema}
            withNamedObjects={withNamedObjects}
            hidden={hidden}
            cleanWhenHidden={cleanWhenHidden}
            rootDocument={rootDocument}
            actions={actions}
            path={path}
            name={name}
        />
    );
};

Address.propTypes = {
    recordsTree: PropTypes.bool,
    template: PropTypes.object,
    stepName: PropTypes.string,
    schema: PropTypes.object,
    withNamedObjects: PropTypes.bool,
    hidden: PropTypes.bool,
    cleanWhenHidden: PropTypes.bool,
    rootDocument: PropTypes.object,
    actions: PropTypes.object,
    path: PropTypes.array
};

Address.defaultProps = {
    template: {},
    stepName: '',
    schema: {},
    withNamedObjects: null,
    recordsTree: null,
    hidden: false,
    cleanWhenHidden: false,
    rootDocument: {},
    actions: {},
    path: []
};

export default Address;
