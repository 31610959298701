import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { translate } from 'react-translate';
import { Chip } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import menuTree from 'modules/analytics/components/AnalyticsMenu/menuTree';

const styles = {
    link: {
        textDecoration: 'none'
    },
    chip: {
        marginRight: 8,
        marginBottom: 8,
        cursor: 'pointer',
        height: 38,
        borderRadius: 22,
        border: '2px solid #e0e0e0',
        '& > span': {
            paddingLeft: 28,
            paddingRight: 28,
            fontSize: 14
        }
    },
    active: {
        border: '2px solid #000000',
        cursor: 'default'
    }
};

const AnalyticsMenu = ({ t, classes, rootPath = '', path = [], items = menuTree }) => {
    const activeMenuPath = path.shift();
    const activeMenuIndex = items.findIndex(({ path: itemPath }) => itemPath === activeMenuPath);
    const activeMenuItem = items[activeMenuIndex];

    return (
        <>
            <div className={classes.root}>
                {items.map((item, index) => {
                    const isActive = index === activeMenuIndex;
                    const chip = (
                        <Chip
                            key={index}
                            label={t(item.name)}
                            className={classNames(classes.chip, { [classes.active]: isActive })}
                            variant={isActive ? 'outlined' : 'default'}
                        />
                    );

                    return isActive ? chip : (
                        <Link to={rootPath + (item.path ? '/' + item.path : '')} className={classes.link}>
                            {chip}
                        </Link>
                    );
                })}
            </div>
            {(activeMenuItem && activeMenuItem.children) ? (
                <AnalyticsMenu
                    t={t}
                    classes={classes}
                    path={path}
                    items={activeMenuItem.children}
                    rootPath={rootPath + (activeMenuItem.path ? '/' + activeMenuItem.path : '')}
                />
            ) : null}
        </>
    );
};

const styled = withStyles(styles)(AnalyticsMenu);
export default translate('Analytics')(styled);