import React from 'react';

import DescriptionIcon from '@mui/icons-material/Description';

const access = { userHasUnit: 101000355 };

export default {
    navigation: [{
        id: 'ElectionFunds',
        title: 'ElectionFunds',
        priority: 20,
        icon: <DescriptionIcon />,
        renderHeaderAnyway: true,
        access,
        children: [{
            id: 'SendDocument',
            title: 'SendDocument',
            path: '/tasks/create/101099/101099001',
            access
        }]
    }]
};
