import React from 'react';
import { createContextMenuComponent } from 'react-datasheet-grid';

const ContextMenu = ({
  t,
  value
}) => createContextMenuComponent((item) => {

  if (item.type === 'INSERT_ROW_BELLOW') {
    return <>{t('INSERT_ROW_BELLOW')}</>;
  }

  if (item.type === 'DUPLICATE_ROW') {
    return <>{t('DUPLICATE_ROW')}</>;
  }

  if (item.type === 'DELETE_ROW') {
    if (value.length === 1) {
      return null;
    }
    return <>{t('DELETE_ROW')}</>;
  }
});

export default ContextMenu