import React from 'react';

import StorageIcon from '@mui/icons-material/Storage';

import RegistryPage from './pages/Registry';

export default {
    routes: [
        {
            path: '/registry',
            component: RegistryPage,
            access: { unitHasAccessTo: 'navigation.registry.RegistryPage' }
        }
    ],
    navigation: [{
        id: 'Registry',
        priority: 15,
        icon: <StorageIcon />,
        path: '/registry',
        access: { unitHasAccessTo: 'navigation.registry.RegistryPage' }
    }]
};
