import React from 'react';
import objectPath from 'object-path';
import { translate } from 'react-translate';

import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { objectArrayUnique } from 'helpers/arrayUnique';
import stringToNumber from 'helpers/stringToNumber';

const styles = {
    root: {
        padding: '20px 0 0'
    }
};

const totalCost = (costPropertyName, loosePropertyName) => data => data
    // .filter(row => !row[loosePropertyName])
    .reduce((acc, row) => acc + stringToNumber(row[costPropertyName]), 0)
    .toFixed(2);

const TotalCostTable = ({ t, classes, range, compare, rangeData, compareData, tableDataPath, costPropertyName, loosePropertyName, idPropertyName }) => {
    if ((!range && !compare) || !costPropertyName) {
        return null
    }

    const calculateTotalCost = totalCost(costPropertyName, loosePropertyName);
    const totalRangeDataCost = calculateTotalCost(rangeData);
    const totalCompareDataCost = calculateTotalCost(compareData);

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                {
                    range ? (
                        <Grid item lg={5} md={6} xs={12}>
                            <Typography variant="h6">
                                {t('TotalRangeDataCost', { totalDataCost: totalRangeDataCost })}
                            </Typography>
                            {
                                range.map((row, index) => (
                                    <Typography>
                                        {t('TotalRangeDataCostItem', {
                                            label: row.label,
                                            totalDataCost: calculateTotalCost(objectArrayUnique(objectPath.get(row, tableDataPath), idPropertyName))
                                        })}
                                    </Typography>
                                ))
                            }
                        </Grid>
                    ) : <Grid item lg={5} md={6} xs={12} />
                }
                {
                    compare ? (
                        <Grid item lg={5} md={6} xs={12}>
                            <Typography variant="h6">
                                {t('TotalRangeDataCost', { totalDataCost: totalCompareDataCost })}
                            </Typography>
                            {
                                compare.map((row, index) => (
                                    <Typography>
                                        {t('TotalRangeDataCostItem', {
                                            label: row.label,
                                            totalDataCost: calculateTotalCost(objectArrayUnique(objectPath.get(row, tableDataPath), idPropertyName))
                                        })}
                                    </Typography>
                                ))
                            }
                        </Grid>
                    ) : null
                }
            </Grid>
        </div>
    );
};

const styled = withStyles(styles)(TotalCostTable);
export default translate('Analytics')(styled);
