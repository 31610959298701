import localization from 'core/translation/uk-UA.js';

export default {
    ...localization,
    App: {
        ...localization.App,
        'Failed to fetch': 'На жаль швидкості Вашого інтернет з\'єднання недостатньо. Спробуйте інший канал зв\'язку та оновіть сторінку',
    },
    PageTitles: {
        ...localization.PageTitles,
        RealEstate: 'Нерухомість',
        Movables: 'Рухоме майно',
        Transport: 'Транспорт',
        Securities: 'Цінні папери',
        Money: 'Гроші',
        IntangibleAssets: 'Нематеріальні активи',
        Organizations: 'Організації',
        Enterprises: 'Підприємства',
        Contributions: 'Внески',
        Payments: 'Платежі',
        FinancialLiabilities: 'Фінансові зобов\'язання',
        RiskyOperations: 'Ризикові операції',
        RegistryAnalytics: 'Реєстри',
        Users: 'Користувачі без праву підпису',
        Leaders: 'Керівники',
        ConMoney: 'Внески',
        OtherCon: 'Внески',
        Coasts: 'Внески',
        Other: 'Внески',
        paymentGovName: 'Платежі',
        OtherName: 'Платежі',
        CoastsName: 'Платежі',
        OtherCostsName: 'Платежі',
        PaymentGov: 'Платежі',
    },
    Navigator: {
        ...localization.Navigator,
        AddNewReport: 'Подати квартальний звіт',
        AddMonitoring: 'Моніторинг для ПП',
        AddCandidateReport: 'Подати звіт виборчого фонду',
        Affiliate: 'Управління організацією',
        OfficeManagement: 'Дії з партіями/осередками',
        OfficeManagementTVK: 'Дії з територіальними/регіональними представництвами',
        CandidateManagement: 'Дії з кандидатами',
        ManagereManagement: 'Дії з розпорядниками',
        RegionalOfficeCreate: 'Створення регіонального підрозділу',
        RegionalOfficeTVKCreate: 'Створення представництва',
        RegionalOfficeTVKDeactivate: 'Активація/деактивація представництва',
        RegionalOfficeTVKSettings: 'Редагування представництва',
        CandidateCreate: 'Створення кандидата',
        CandidateDeactivate: 'Активація/деактивація кандидата',
        CandidateSettings: 'Редагування кандидата',
        ManagereCreate: 'Створення розпорядника',
        ManagereSettings: 'Активація/деактивація розпорядника',
        ManagereDeactivate: 'Редагування розпорядника',
        PartySettings: 'Редагування політичної партії/регіонального підрозділу',
        UnitManagement: 'Управління подрозділами та користувачами',
        SettingUnitHead: 'Призначити роль "Керівник Регіонального офісу"',
        PartyDeactivate: 'Деактивація / активація політичної партії',
        RegionalPartyDeactivate: 'Деактивація регіонального підрозділу',
        PartyUsersManagement: 'Управління користувачами',
        PartyCreate: 'Створення політичної партії',
        Tasks: 'Поточна робота',
        UnitInboxTasks: 'Задачі організації',
        UnitClosedTasks: 'Архів задач організації',
        MyWorkflow: 'Стан виконання задач',
        Registry: 'Реєстри',
        Workflow: '',
        Analytics: 'Аналітика',
        ElectionFunds: 'Виборчі фонди',
        SendDocument: 'Надіслати документи',
        Leaders: 'Керівники'
    },
    TaskListPage: {
        ...localization.TaskListPage,
        InboxTasksTitle: 'Мої задачі',
        DocumentNumber: 'Номер',
        UnitInboxTasksTitle: 'Задачі підрозділу',
        ClosedTasksTitle: 'Архів моїх задач',
        UnitClosedTasksTitle: 'Архів задач організації',
    },
    WorkflowListPage: {
        ...localization.WorkflowListPage,
        InboxTitle: 'Стан виконання задач',
        EmptyTrashListDescription: '',
        WorkflowNumber: 'Номер',
        WorkflowName: 'Задача',
        OrderedAt: 'Дата створення',
    },
    NazkReportList: {
        PartyName: 'Назва регіонального офісу',
        ReportDate: 'Дата поданого звіту',
        Download: 'Завантажити',
        NoReportDate: 'не подано',
        downLoadPdf: 'Завантажити обрані Pdf',
        downLoadXLSX: 'Завантажити обрані XLSX',
        reload: 'Оновити',
        Info: 'Інформація щодо підписання документу за допомогою кваліфікованого електронного підпису (КЕП):',
        FileTitle: 'Назва файлу:',
        Person: 'Особа, що підписала документ:',
        DateTime: 'Дата та час підписання документу:',
        DocumentId: "Ідентифікатор документа в Єдиному державному реєстрі звітності політичних партій про майно, доходи, витрати і зобов'язання фінансового характеру:",
        ACSK: 'АЦСК КЕП:',
        signerInfo: 'Інформація про підпис'
    },
    FileDataTable: {
        ...localization.FileDataTable,
        Info: 'Інформація щодо підписання документу за допомогою кваліфікованого електронного підпису (КЕП):',
        FileTitle: 'Назва файлу:',
        Person: 'Особа, що підписала документ:',
        DateTime: 'Дата та час підписання документу:',
        DocumentId: "Ідентифікатор документа в Єдиному державному реєстрі звітності політичних партій про майно, доходи, витрати і зобов'язання фінансового характеру:",
        ACSK: 'АЦСК КЕП:'
    },
    Analytics: {
        PartySelect: 'Оберіть партію',
        OfficeSelect: 'Оберіть регіональний підрозділ',
        PartyInfo: 'Про партію',
        Registry: 'Реєстри',
        Organizatuions: 'Організації',
        Enterprises: 'Підприємства',
        Properties: 'Майно',
        RealEstate: 'Нерухомість',
        Movables: 'Рухоме майно',
        Transport: 'Транспорт',
        Securities: 'Цінні папери',
        Money: 'Гроші',
        IntangibleAssets: 'Нематеріальні активи',
        Contributions: 'Внески',
        Payments: 'Платежі',
        FinancialLiabilities: 'Фінансові зобов\'язання',
        RiskyOperations: 'Ризикові операції',
        Compare: 'порівняти',
        ReportRangeSelect: 'Звітній період',
        ReportCompare: 'Порівняно з',
        ObjectType: 'Вид об\'єкта',
        ObjectLocation: 'Місцезнаходження',
        ObjectAcquirementDate: 'Дата набуття права',
        ObjectCost: 'Вартість на дату набуття права',
        ObjectSquere: 'Загальна площа (м.кв.)',
        ObjectNumber: 'Реєстраційний номер/кадастровий номер для земельної ділянки',
        ObjectRightsInfo: 'Інформація щодо прав на об\'єкт',
        ObjectAlienationDate: 'Дата відчуження',
        ObjectOwner: 'ПІБ власника',
        ObjectRNOKPP: 'РНОКПП, серія та номер паспорта, номер ID-картки/ ідентифікаційний код юридичної особи',
        ObjectOwnerType: 'Тип особи',
        PolitParty: 'Політична партія',
        RegionalOffice: 'Регіональний офіс',
        PolitPartyHead: 'Керівник',
        PartyName: 'Найменування',
        PartyCode: 'Ідентифікаційний код юридичної особи (ЄДРПОУ)',
        PartyLocation: 'Юридична адреса',
        HeadName: 'Прізвище, ім’я, по батькові',
        HeadIpn: 'Реєстраційний номер облікової картки платника податків',
        HeadDateOfBirth: 'Дата народження',
        HeadPassport: 'Паспортні дані',
        MovablesType: 'Вид майна',
        MovablesOwnType: 'Вид власності',
        ObjectDescription: 'Опис майна',
        ObjectManufacturer: 'Найменування виробника',
        ObjectTradeMark: 'Торгова марка',
        Identificator: 'Ідентифікаційний номер',
        Trademark: 'Марка',
        Model: 'Модель',
        IssueYear: 'Рік випуску',
        PaperType: 'Вид паперів',
        PaperCount: 'Кількість цінних паперів',
        Emitend: 'Емітент цінних паперів',
        EmitendInn: 'Ідентифікаційний код емітента',
        PaperCost: 'Номінальна вартість цінного папера',
        BankName: 'Найменування установи, в якій відкрито рахунок',
        BankIpn: 'Ідентифікаційний код установи, в якій відкрито рахунок',
        BankAccountType: 'Вид рахунку',
        BankAccount: 'Номер рахунку',
        BankAccountInfo: 'Інформація про рахунок',
        RemainderOnStart: 'Залишок коштів, розміщених на рахунку на початок звітного періоду',
        TotalAdded: 'Усього надійшло коштів на рахунок за звітний період',
        TotalSpended: 'Усього використано коштів з рахунку за звітний період',
        RemainderOnEnd: 'Залишок коштів, розміщених на рахунку на кінець звітного періоду',
        Count: 'Кількість',
        AssetsType: 'Вид активу',
        AssetsDate: 'Дата виникнення права',
        AssetsCost: 'Вартість активу на дату виникнення права',
        AssetsDescription: 'Опис активу',
        AssetsRights: 'Інформація щодо прав на об\'єкт',
        AssetsAlienationDate: 'Дата відчуження активу',
        Organizations: 'Організації',
        OrganizationName: 'Найменування',
        OrganizationCode: 'Ідентифікаційний код юридичної особи',
        EnterpriseName: 'Найменування',
        EnterpriseCode: 'Ідентифікаційний код юридичної особи',
        ContributionOwnerCode: 'ПІБ фізичної особи/ юридична особа, яка здійснила внесок',
        ContributionOwnerType: 'Тип особи, що здійснила внесок',
        ContributionAcquirementDate: 'Дата надання внеску',
        ContributionSum: 'Сума внеску',
        ContributionAlienationDate: 'Сума повернення/перерахування',
        PaymentDate: 'Дата здійснення платежу',
        PaymentReason: 'Підстава для здійснення платежу',
        PaymentOwnerCode: 'ПІБ фізичної особи/ юридична особа, на користь якої здійснено платіж',
        PaymentTarget: 'Цільове призначення платежу',
        Sum: 'Сума',
        ObligateType: 'Вид зобов\'язання',
        ObligateReason: 'Підстава виникнення',
        ObligateDate: 'Дата виникнення зобов\'язання',
        ObligatePerson: 'ПІБ фізичної особи/юридична особа, на користь якої існує зобов\'язання',
        ObligateSum: 'Сума зобов\'язання на дату його виникнення',
        ObligateRemainder: 'Залишок невиконаного зобов\'язання станом на кінець звітного періоду',
        TotalRangeDataCost: 'Загальна вартість об’єктів: {{totalDataCost}} грн',
        TotalRangeDataCostItem: 'Вартість об’єктів {{label}}: {{totalDataCost}} грн',
        EmployeeCount: 'Кількість працюючих',
        QuantityFirst: 'На умовах трудового договору',
        QuantitySecond: 'На умовах цивільно-правового договору',
        QuantityThird: 'На громадських засадах',
        Building: 'будинок',
        HeadLocation: 'Адреса реєстрації',
        RiskyOperationsEmptyTitle: 'Ризикових операцій не виявлено',
        RiskyOperationsEmptyDescription: '',
        EDRPOUCode: '{{name}} (код ЄДРПОУ {{code}})',
        ReportName: 'Звіт',
        AnalitycsReport: 'Аналітичний звіт',
        ExportDataToPdf: 'Експортувати дані',
        AllOffices: 'Усі підрозділи',
        RegistryAnalytics: 'Реєстри',
        RegistryEmptyTitle: 'Записи відсутні',
        'Can not get nazk reports.': 'Записи відсутні',
        Search: 'Пошук',
        Warning: 'Увага',
        NoContent: 'Немає даних',
        DownloadRiskyOperation: 'Завантажити деталі',
        Close: 'Закрити',
        RiskyOperationFileName: 'Звіт.html',
        ConMoney: 'Грошові внески',
        OtherCon: 'Інші внески',
        Coasts: 'Кошти державного фінансування',
        PaymentGov: 'Платежі з окремого рахунку, відкритого для зарахування коштів з державного бюджету',
        Other: 'Інші надходження',
        other1: "Вид внеску",
        other2: "ПІБ фізичної особи/най-менування юридичної особи, яка здійснила внесок",
        other3: "РНОКПП, серія та номер паспорта, номер ID-картки/ ідентифікаційний код юридичної особи",
        other4: "Тип особи, що здійснила внесок",
        other5: "Дата народження фізичної особи, яка здійснила внесок",
        other6: "Місце проживання/ місцезнаходження особи, яка здійснила внесок",
        other7: "Дата надання внеску",
        other8: "Реєстраційний номер/кадастровий номер для земельної ділянки",
        other9: "Ринкова вартість (за  методологією, затвердженою Національним агентством з питань запобігання корупції)",
        other10: "Вартість майнового внеску за договором",
        costs7: "Найменування установи, в якій відкрито рахунок",
        costs1: "Форма державного фінансування",
        costs2: "Ідентифікаційний код установи",
        costs3: "Номер рахунку",
        costs4: "Дата надходження",
        costs5: "Сума",
        costs6: "Сума повернення/перерахування",
        otherCosts1: "Вид надходження (доходи, майно та нематеріальні активи, які не є внесками відповідно до закону; кошти, які не є ні доходами, ні внесками, отримані політичною партією, місцевою організацією політичної партії, яка в установленому порядку набула статусу юридичної особи, протягом звітного кварталу)",
        otherCosts2: "Опис надходження",
        otherCosts3: "ПІБ фізичної особи/ найменування юридичної особи",
        otherCosts4: "Тип особи",
        otherCosts5: "Реєстраційний номер/ кадастровий номер для земельної ділянки",
        otherCosts6: "Дата надходження",
        otherCosts7: "Найменування установи, в якій відкрито рахунок політичної партії/її  місцевої організації",
        otherCosts8: "Ідентифіка-ційний код установи",
        otherCosts9: "Номер рахунку",
        otherCosts10: "Сума/ вартість  надходження за договором",
        other1payments: "Найменування установи, в якій відкрито рахунок",
        other2payments: "Ідентифікаційний код установи",
        other3payments: "Номер рахунку",
        other4payments: "Вид рахунку",
        other5payments: "Дата здійснення платежу",
        other6payments: "Підстава для здійснення платежу",
        other7payments: "ПІБ фізичної особи/найменування юридичної особи, на користь якої здійснено платіж",
        other8payments: "Тип особи",
        other9payments: "РНОКПП, серія та номер паспорта, номер ID-картки/ ідентифікаційний код отримувача",
        other10payments: "Місце проживання/місцезнаходження особи",
        other11payments: "Цільове призначення платежу",
        other12payments: "Сума",
        costs1payments: "Найменування установи, в якій відкрито рахунок політичної партії/її місцевої організації",
        costs2payments: "Ідентифікаційний код установи",
        costs3payments: "Номер рахунку",
        costs4payments: "Дата отримання",
        costs5payments: "ПІБ фізичної особи/ найменування юридичної особи",
        costs14payments: "Тип особи",
        costs6payments: "РНОКПП, серія та номер паспорта, номер ID-картки/ідентифікацій-ний код юридичної особи",
        costs7payments: "Сума",
        costs8payments: "Дата повернення/перерахування",
        costs9payments: "ПІБ фізичної особи/ найменування юридичної особи",
        costs15payments: "Тип особи",
        costs10payments: "РНОКПП, серія та номер паспорта, номер ID-картки/ідентифікацій-ний код юридичної особи",
        costs11payments: "Обгрунтування (повернення грошових внесків (чи їх частин) та помилкових грошових надходжень; повернення до державного бюджету невикористаної частини коштів державного фінансування, перерахування до державного бюджету сум внесків, які неможливо повернути особам, які їх здійснили)",
        costs12payments: "Сума повернення",
        costs13payments: "Сума, що перераховується (повертається) до державного бюджету",
        otherCosts1payments: "Вид внеску (майнові внески; внески роботами, товарами або послугами; внески нематеріальними активами; внески, здійснені шляхом фінансування заходів або діяльності на користь політичної партії тощо)",
        otherCosts2payments: "Реєстрацій-ний номер/ кадастро-вий номер для земельної ділянки",
        otherCosts3payments: "Дата отримання внеску",
        otherCosts4payments: "ПІБ фізичної особи/ найменування юридичної особи",
        otherCosts13payments: "Тип особи",
        otherCosts5payments: "РНОКПП, серія та номер паспорта, номер ID-картки/ідентифікацій-ний код юридичної особи",
        otherCosts6payments: "Сума",
        otherCosts7payments: "Дата повернення/перерахування",
        otherCosts8payments: "ПІБ фізичної особи/ найменування юридичної особи",
        otherCosts14payments: "Тип особи",
        otherCosts9payments: "РНОКПП, серія та номер паспорта, номер ID-картки/ідентифікацій-ний код юридичної особи",
        otherCosts10payments: "Обгрунтування (повернення майнових внесків; внесків роботами, товарами або послугами; внесків нематеріальними активами; внесків, здійснених шляхом фінансування заходів або діяльності на користь політичної партії; перерахування до державного бюджету сум внесків, які неможливо повернути особам, які їх здійснили, тощо)",
        otherCosts11payments: "Сума повернення",
        otherCosts12payments: "Сума, що перераховується (повертається) до державного бюджету",
        paymentGovName: 'Платежі з окремого рахунку, відкритого для зарахування коштів з державного бюджету',
        OtherName: 'Платежі з інших рахунків політичної партії/місцевої організації',
        CoastsName: 'Інформація про повернення (перерахування) грошових внесків (чи їх частин) та помилкових грошових надходжень',
        OtherCostsName: 'Інформація про повернення та перерахування інших внесків'
    }, 
    NazkRegistry: {
        Area: 'Площа',
        LivingArea: 'Житлова площа',
        RegNum: 'Реєстраційний номер',
        IsResidentialBuilding: 'Є житловим приміщенням',
        RegDate: 'Дата реєстрації',
        TechDescription: 'Технічний опис',
        HasProperties: 'Має параметри',
        Properties: 'Параметри',
        RealtyAddress: 'Адреса нерухомості',
        ReType: 'Тип',
        ReState: 'Стан',
        SectionType: 'Розділ',
        Region: 'Регіон',
        RnNum: 'Реєстраціїний номер',
        PartSize: 'Частка',
        Subjects: 'Суб\'єкти',
        CauseDocuments: 'Документи',
        PrType: 'Власність',
        PrState: 'Стан',
        Registrar: 'Реєстратор',
        EntityLinks: 'Посилання',
        OperationReason: 'Підстава операції',
        SbjName: 'Найменування',
        DcSbjType: 'Тип',
        SbjSort: 'Сортування',
        CountryName: 'Країна',
        CdType: 'Тип документа',
        DocDate: 'Дата',
        Publisher: 'Видавець',
        Enum: 'Порядковий номер',
        RealtyParts: 'Частини нерухомості',
        RepTypeExtension: 'Тип',
        ReInCreation: '',
        Letter: 'Літера',
        RepType: 'Тип',
        Mortgage: 'Іпотека',
        ObjectDescription: 'Опис об\'єкту',
        Obligations: 'Зобов\'язання',
        ExecTerm: 'Термін',
        ObligationSum: 'Сума',
        CurrencyType: 'Валюта',
        SbjRlName: 'Тип',
        MgState: 'Стан',
        Limitation: 'Обмеження',
        LmType: 'Тип',
        Bond: 'Застава'
    },
    UserListPage: {
        ...localization.UserListPage,
        Users: 'Користувачі без праву підпису',
    },
    UserLeaderList: {
        Leaders: 'Керівники',
        MainOffice: 'Головні офіси',
        RegionalOffice: 'Регіональні офіси',
        CVK: 'ЦВК',
        RegionalCVK: 'Територіальні/регіональні представництва',
        Name: 'Назва офісу',
        Code: 'ЄДРПОУ',
        LastName: 'Прізвище',
        FirstName: 'Ім\'я',
        MiddleName: 'По батькові',
        Ipn: 'РНОКПП',
        Email: 'Електронна пошта'
    }
};