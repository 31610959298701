import React from 'react';
import PropTypes from 'prop-types';

import LeftSidebarLayout from 'layouts/LeftSidebar';
import Preloader from 'components/Preloader';
import InboxFilesTable from 'modules/inbox/pages/InboxFilesList/components/InboxFilesTable';


const InboxFileListLayout = ({ location, title, loading, data, fileStorage, handleItemClick }) => (
    <LeftSidebarLayout
        location={location}
        title={title}
        loading={loading}
    >
        {data ? (
            <InboxFilesTable
                fileStorage={fileStorage}
                handleItemClick={handleItemClick}
            />
        ) : <Preloader />}
    </LeftSidebarLayout>
);

InboxFileListLayout.propTypes = {
    location: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    data: PropTypes.array,
    fileStorage: PropTypes.object,
    handleItemClick: PropTypes.func
};

InboxFileListLayout.defaultProps = {
    loading: false,
    data: null,
    fileStorage: {},
    handleItemClick: () => null
};

export default InboxFileListLayout;
