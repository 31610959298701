/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LeftSidebarLayout from 'layouts/LeftSidebar';

import PopupCheckValidTools from 'modules/tasks/pages/Task/debugTools/PopupCheckValidTools';
import PopupDebugTools from 'modules/tasks/pages/Task/debugTools/PopupDebugTools';
import TaskDataTools from 'modules/tasks/pages/Task/debugTools/TaskDataTools';
import CheckValidFunction from 'modules/tasks/pages/Task/debugTools/CheckValidFunction';
import CheckHiddenFunction from 'modules/tasks/pages/Task/debugTools/CheckHiddenFunction';

const TaskPageLayout = ({ popupDebugTools, children, location, title, loading, task, template, stepId, flexContent }) => {
    const debugTools = {
        TaskDataTools: <TaskDataTools task={task} template={template} />,
        CheckValidFunction: <CheckValidFunction task={task} template={template} stepId={stepId} />,
        CheckHiddenFunction: <CheckHiddenFunction task={task} template={template} />
    };

    if (popupDebugTools) {
        debugTools.PopupDebugTools = <PopupDebugTools {...popupDebugTools} />;
        debugTools.PopupCheckValidTools = <PopupCheckValidTools {...popupDebugTools} />;
    }

    return (
        <LeftSidebarLayout
            disableScrolls={true}
            flexContent={flexContent}
            location={location}
            title={title}
            loading={loading}
            debugTools={debugTools}
        >
            {children}
        </LeftSidebarLayout>
    );
};

TaskPageLayout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    location: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    task: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired
};

const mapStateToProps = ({ debugTools: { popup } }) => ({ popupDebugTools: popup });
export default connect(mapStateToProps)(TaskPageLayout);
