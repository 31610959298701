import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import parts from 'modules/analytics/pages/RiskyOperations/parts';


const styles = {
    root: {
        marginTop: 10
    },
    preformatted: {
        whiteSpace: 'pre',
        overflow: 'auto',
        overflowY: 'hidden'
    },
    details: {
        flexDirection: 'column'
    }
};

const RiskyOperation = ({ classes, operation: { operationId, data, rule: { title, description } = {} } = {} } = {}) => (
    <Paper className={classes.root}>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.heading}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <Typography className={classes.preformatted}>
                    {description}
                </Typography>
            </AccordionDetails>
        </Accordion>
        {[].concat(data).map((part, index) => {
            const PartComponent = parts[part.type];

            if (!PartComponent) {
                return [part.type, 'не налаштований'].join(' ');
            }

            return <PartComponent part={part} />
        })}
    </Paper>
);

export default withStyles(styles)(RiskyOperation);
