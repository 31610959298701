import React from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';

import { Chip, MobileStepper, Stepper, Step, StepLabel, Hidden, Typography, Toolbar } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InfoIcon from '@mui/icons-material/Info';

import TimeLabel from 'components/Label/Time';

import classNames from 'classnames';

import humanDateFormat from 'helpers/humanDateFormat';

const colors = {
    1: '#3a9ae6',
    2: '#60b52a',
    3: '#bf3229',
    null: '#5a5a5a'
};

const styles = theme => ({
    toolbar: {
        display: 'block',
        padding: 10,
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    wrapper: {
        backgroundColor: '#eeeeee'
    },
    chip: {
        marginRight: 20,
        border: 'none'
    },
    activeChip: {
        cursor: 'pointer'
    },
    stepperToolbar: {
        padding: '0 4px'
    },
    steeper: {
        backgroundColor: '#eeeeee',
        overflowY: 'auto'
    },
    mobileStepper: {
        backgroundColor: '#eeeeee',
        width: '100%',
        '& > div': {
            margin: 'auto'
        }
    },
    time: {
        textAlign: 'center'
    }
});

const Header = ({
    t,
    classes,
    workflow,
    workflow: { workflowStatusId },
    timeline
}) => (
        <div className={classes.wrapper}>
            <Toolbar className={classes.toolbar}>
                {timeline.length ? (
                    <Chip
                        color="primary"
                        label={timeline[timeline.length - 1].label}
                        className={classes.chip}
                        style={{ cursor: 'inherit', backgroundColor: colors[workflowStatusId], color: 'white' }}
                    />
                ) : null}
                <Chip
                    icon={<CalendarTodayIcon />}
                    label={t('CreatedAt', { time: humanDateFormat(workflow.entryTaskFinishedAt) })}
                    className={classes.chip}
                    variant="outlined"
                />
                {(workflow.info || []).map((info, key) => (
                    <a key={key} href={info.link} target="_blank" rel="noopener noreferrer">
                        <Chip
                            icon={<InfoIcon />}
                            label={info.name}
                            className={classNames(classes.chip, classes.activeChip)}
                            variant="outlined"
                        />
                    </a>
                ))}
            </Toolbar>
            <Hidden mdDown={true} implementation="css">
                <Stepper alternativeLabel={true} activeStep={timeline.length - 1} className={classes.steeper}>
                    {timeline.map((step, index) => (
                        <Step
                            key={index}
                            title={step.label}
                            className={classes.step}
                        >
                            <StepLabel
                                className={classes.time}
                                optional={(
                                    <Typography align="center" variant="caption">
                                        <TimeLabel date={step.finishedAt} />
                                    </Typography>
                                )}
                            >
                                {step.label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Hidden>
            <Hidden mdUp={true}>
                <MobileStepper
                    variant="dots"
                    steps={timeline.length}
                    position="static"
                    activeStep={timeline.length - 1}
                    className={classes.mobileStepper}
                />
            </Hidden>
        </div>
    );

Header.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    workflow: PropTypes.object,
    timeline: PropTypes.array
};

Header.defaultProps = {
    workflow: {},
    timeline: []
};

const translated = translate('WorkflowPage')(Header);
export default withStyles(styles)(translated);
