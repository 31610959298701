import React from 'react';

import Preloader from 'components/Preloader';
import EmptyPage from 'components/EmptyPage';

import asModulePage from 'hooks/asModulePage';
import useTable from 'services/dataTable/useTable';
import withRange from 'modules/analytics/hooks/withRange';
import analyticsPage from 'modules/analytics/hooks/analyticsPage';

import endPoint from 'components/PartySelect/endPoint';
import RiskyOperation from 'modules/analytics/pages/RiskyOperations/RiskyOperation';
import DownloadRiskyOperation from 'modules/analytics/pages/RiskyOperations/components/DownloadRiskyOperation';

import mockData from 'modules/analytics/pages/RiskyOperations/mock/data.json';

import config from 'config';

const { useRiskyMockData } = config;

const RiskyOperations = ({ t, range }) => {
    const { id: rangeReportId } = range || {};

    const {
        data: useTableData,
        filters,
        loading,
        actions,
        filters: { data_like: { id: rangeEntityId } = {} }
    } = useTable(endPoint, { rowsPerPage: 1000, filters: { keyId: 27 } });

    const data = useRiskyMockData ? mockData : useTableData;

    React.useEffect(() => {
        if (rangeReportId !== rangeEntityId && rangeReportId) {
            actions.onFilterChange({ ...filters, data_like: { id: rangeReportId } }, !useRiskyMockData);
        }
    }, [actions, filters, rangeEntityId, rangeReportId]);

    if (loading && rangeReportId) {
        return <Preloader />;
    }

    if (!data || !rangeReportId) {
        return null;
    }

    if (!data.length) {
        return (
            <EmptyPage
                title={t('RiskyOperationsEmptyTitle')}
                description={t('RiskyOperationsEmptyDescription')}
            />
        );
    }

    console.log('data', data);

    return (
        <>
            <DownloadRiskyOperation reportId={rangeReportId} />
            {[]
                .concat(...data.map(({ data: { results = [] } = {} }) => results))
                .map((part, index) => <RiskyOperation key={index} operation={part} />)}
        </>
    );
}

const ranged = withRange(RiskyOperations, { multiple: false, canCompare: false });
const asPage = analyticsPage(ranged);
export default asModulePage(asPage);
