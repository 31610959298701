import elements from 'application/components/JsonSchema/elements';

import NazkReportList from './NazkReportList';
import NazkPartySelect from './NazkPartySelect';

export default {
    ...elements,
    NazkReportList,
    NazkPartySelect
};
