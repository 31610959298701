export default [{
    source: 'moneyElectionFundAccounts',
    sheetName: '0.6',
    startRow: 4,
    startCell: 1,
    columns: ['index','regionNumber', 'naming', 'typeOfAcc', 'accNumber', 'totalSum', 'totalUsed', 'leftMoney']
}, {
    source: 'contributionOwnCandidateMoney',
    sheetName: '0.7',
    startRow: 4,
    startCell: 1,
    columns: ['index', 'articleCode', 'dateIncome', 'docNumber', 'accNumber', 'typeOfIncome', 'candidateFIO', 'sum', 'totalSum']
}, {
    source: 'contributionVoluntaryFizUr',
    sheetName: '1.1',
    startRow: 4,
    startCell: 1,
    columns: ['index', 'articleCode', 'dateIncome', 'docNumber', 'userFIO', 'useLivingPlace', 'rnokpp', 'dateOfBirth', 'unzr', 'sum', 'totalSum']
}, {
    source: 'contributionWrong',
    sheetName: '1.2',
    startRow: 4,
    startCell: 1,
    columns: ['index', 'articleCode', 'dateIncome', 'docNumber', 'userFIO', 'useLivingPlace', 'rnokpp', 'unzr', 'typeOfUser', 'purposeOfPayment', 'sum', 'totalSum']
}, {
    source: 'receivingOnCurrentAccounts',
    sheetName: '1.3',
    startRow: 4,
    startCell: 1,
    columns: ['index', 'regionNumber', 'articleCode', 'dateIncome', 'docNumber', 'typeOfIncome', 'userFIO', 'userLivingPlace', 'rnokpp', 'unzr', 'typeOfUser', 'contractDetails', 'purposeOfPayment', 'sum', 'totalSum']
}, {
    source: 'transferMoneyFromAccumulationAccount',
    sheetName: '1.4',
    startRow: 4,
    startCell: 1,
    columns: ['index', 'articleCode', 'dateIncome', 'typeOfIncome', 'accNumber', 'docNumber', 'userFIO', 'userLivingPlace', 'rnokpp', 'unzr', 'typeOfUser', 'totalSum']
}, {
    source: 'refundsMoneyFromCurrentAccount',
    sheetName: '1.5',
    startRow: 4,
    startCell: 1,
    columns: ['index', 'regionNumber', 'articleCode', 'dateIncome', 'typeOfIncome', 'accNumber', 'docNumber', 'userFIO', 'userLivingPlace', 'rnokpp', 'unzr', 'typeOfUser', 'contractInfo', 'purposeOfPayment', 'sum', 'totalSum']
}, {
    source: 'getMoneyFromVolunteerContributorFizUr',
    sheetName: '1.6',
    startRow: 4,
    startCell: 1,
    columns: ['index', 'articleCode', 'dateIncome', 'userFIO', 'rnokpp', 'unzr', 'sum']
}, {
    source: 'refundsMoneyFromVolunteerContributorFizUr',
    sheetName: '2.1',
    startRow: 4,
    startCell: 1,
    columns: ['index', 'articleCode2', 'dateIncomeBack', 'typeOfUser', 'userFIO2', 'rnokpp2', 'unzr2', 'typeOfIncomeBack', 'sumOfIncomeBack', 'sumOfIncomeBackGOV']
}, {
    source: 'paymentsFromElectionFoundAccounts',
    sheetName: '2.2',
    startRow: 4,
    startCell: 1,
    columns: ['index', 'regionNumber', 'articleCode', 'typeOfAcc', 'dateIncome', 'accNumber', 'typeOfWaste', 'typeOfUser', 'userFIO', 'rnokpp', 'unzr', 'userLivingPlace', 'purposeOfPayment', 'sum', 'totalSum']
}];

// obligate
