import React from 'react';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';

import TaskListPage from 'modules/tasks/pages/TaskList';
import TableTools from 'modules/tasks/pages/TaskList/components/TableTools';
import TaskPage from 'modules/tasks/pages/Task';
import OnboardingTaskPage from 'modules/tasks/pages/OnboardingTask';
import UncreatedTask from 'modules/tasks/pages/UncreatedTask';
import MultisignTask from 'modules/tasks/pages/MultisignTask';
import CreateTaskButton from 'modules/tasks/components/CreateTaskButton';
import MonirotingButton from 'modules/tasks/components/MonirotingButton';
import CandidateButton from 'modules/tasks/components/CandidateButton';

import taskEndPoint from 'application/endPoints/task';
import unitTaskEndPoint from 'application/endPoints/unitTask';
import closedTaskEndPoint from 'application/endPoints/closedTask';
import closedUnitTaskEndPoint from 'application/endPoints/closedUnitTask';

import MyTaskNavigation from 'modules/tasks/components/MyTaskNavigation';
import UnitTaskNavigation from 'modules/tasks/components/UnitTaskNavigation';

const taskModules = [{
    title: 'ClosedTasksTitle',
    rootPath: '/tasks/closed-tasks',
    endPoint: closedTaskEndPoint,
    defaultFilters: { finished: 1, deleted: 0, assigned_to: 'me' },
    uiFilter: 'tasks.my.closed',
    hiddenColumns: {
        notUnitedUser: ['applicantName', 'applicantType', 'performerUnits', 'performerUserNames', 'Строк виконання', 'dueDate'],
        isUnitedUser: 'properties.hiddenColumns.tasks.ClosedTasks'
    },
    access: { isUnitedUser: false, unitHasAccessTo: 'navigation.tasks.ClosedTasks' }
}, {
    title: 'UnitClosedTasksTitle',
    rootPath: '/tasks/closed-unit-tasks',
    endPoint: closedUnitTaskEndPoint,
    defaultFilters: { finished: 1, deleted: 0, assigned_to: 'unit' },
    uiFilter: 'tasks.unit.closed',
    hiddenColumns: {
        notUnitedUser: ['performerUserNames'],
        isUnitedUser: 'properties.hiddenColumns.tasks.UnitClosedTasks'
    },
    access: { unitHasAccessTo: 'navigation.tasks.UnitClosedTasks' }
}, {
    title: 'UnitInboxTasksTitle',
    rootPath: '/tasks/unit-tasks',
    endPoint: unitTaskEndPoint,
    defaultFilters: { finished: 0, deleted: 0, assigned_to: 'unit' },
    uiFilter: 'tasks.unit.opened',
    hiddenColumns: {
        notUnitedUser: ['performerUserNames'],
        isUnitedUser: 'properties.hiddenColumns.tasks.UnitInboxTasks'
    },
    access: { unitHasAccessTo: 'navigation.tasks.UnitInboxTasks' }
}, {
    title: 'InboxTasksTitle',
    rootPath: '/tasks/my-tasks',
    endPoint: taskEndPoint,
    defaultFilters: { finished: 0, deleted: 0, assigned_to: 'me' },
    uiFilter: 'tasks.my.opened',
    hiddenColumns: {
        notUnitedUser: ['applicantName', 'applicantType', 'performerUnits', 'performerUserNames', 'Строк виконання', 'dueDate'],
        isUnitedUser: 'properties.hiddenColumns.tasks.InboxTasks'
    },
    access: { isUnitedUser: false, unitHasAccessTo: 'navigation.tasks.InboxTasks' }
}, {
    title: 'InboxTasksTitle',
    rootPath: '/tasks',
    endPoint: taskEndPoint,
    hiddenColumns: {
        notUnitedUser: ['applicantName', 'applicantType', 'performerUnits', 'performerUserNames', 'Строк виконання', 'dueDate'],
        isUnitedUser: 'properties.hiddenColumns.tasks.InboxTasks'
    }
}];

const taskRoutes = [].concat(...taskModules.map(({ rootPath, ...rest }) => ([
    {
        rootPath,
        path: rootPath + '/:taskId/signers/apply',
        component: MultisignTask
    },
    {
        rootPath,
        path: rootPath + '/redirect/:workflowId/:taskTemplateId',
        component: UncreatedTask
    },
    {
        rootPath,
        path: rootPath + '/create/:workflowTemplateId/:taskTemplateId',
        component: TaskPage
    }, {
        rootPath,
        path: rootPath + '/create/:workflowTemplateId',
        component: TaskPage
    }, {
        rootPath,
        path: rootPath + '/:taskId/:stepId',
        component: TaskPage
    }, {
        rootPath,
        path: rootPath + '/:taskId',
        component: TaskPage
    }, {
        ...rest,
        rootPath,
        path: rootPath,
        component: TaskListPage,
        TableToolbar: TableTools
    }
])));

export default {
    routes: [
        {
            path: '/tasks/onBoarding/:stepId',
            component: OnboardingTaskPage,
            isOnboarding: true
        }, {
            path: '/tasks/onBoarding',
            component: OnboardingTaskPage,
            isOnboarding: true
        },
        ...taskRoutes,
        {
            path: '*',
            to: '/tasks/onBoarding',
            redirect: true,
            isOnboarding: true
        }
    ],
    navigation: [
        {
            priority: 60,
            Component: CreateTaskButton,
            access: {
                isUnitedUser: false,
                userHasUnit: [18,21]
            }
        },{
            priority: 60,
            Component: CandidateButton,
            access: {
                isUnitedUser: false,
                userHasUnit: [28,29]
            }
        },{
            priority: 60,
            Component: MonirotingButton,
            access: {
                isUnitedUser: false,
                userHasUnit: [101000355]
            }
        }, {
            id: 'Tasks',
            icon: <WorkOutlineIcon />,
            priority: 40,
            access: {
                isUnitedUser: false,
                unitHasAccessTo: [
                    'navigation.tasks.InboxTasks',
                    'navigation.tasks.UnitInboxTasks',
                    'navigation.tasks.ClosedTasks',
                    'navigation.tasks.UnitClosedTasks'
                ]
            },
            children: [
                {
                    Component: MyTaskNavigation,
                    access: { isUnitedUser: false, unitHasAccessTo: 'navigation.tasks.InboxTasks' }
                }, {
                    Component: UnitTaskNavigation,
                    access: { unitHasAccessTo: 'navigation.tasks.UnitInboxTasks' }
                }, {
                    id: 'MyWorkflow',
                    path: '/workflow',
                    access: { isUnitedUser: false, unitHasAccessTo: 'navigation.workflow.MyWorkflow' }
                }, {
                    Component: () => <div style={{ height: 20 }} />,
                    access: { unitHasAccessTo: 'navigation.tasks.UnitClosedTasks' }
                }, {
                    id: 'ClosedTasks',
                    path: '/tasks/closed-tasks',
                    uiFilter: 'tasks.my.closed',
                    access: { isUnitedUser: false, unitHasAccessTo: 'navigation.tasks.ClosedTasks' }
                }, {
                    id: 'UnitClosedTasks',
                    path: '/tasks/closed-unit-tasks',
                    uiFilter: 'tasks.unit.closed',
                    access: { unitHasAccessTo: 'navigation.tasks.UnitClosedTasks' }
                }, {
                    Component: () => <div style={{ height: 10 }} />,
                    access: { unitHasAccessTo: 'navigation.tasks.UnitClosedTasks' }
                }
            ]
        }
    ]
};
