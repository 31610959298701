import { Tooltip } from "@mui/material";

import withStyles from '@mui/styles/withStyles';

export default withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    }
}))(Tooltip);