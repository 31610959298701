import React from 'react';
import PropTypes from 'prop-types';
import useCountDown from 'react-countdown-hook';
import classNames from 'classnames';
import moment from 'moment';
import { Typography, Fade } from '@mui/material';
import { makeStyles } from '@mui/styles';
import evaluate from 'helpers/evaluate';

const styles = () => ({
  wrapper: {
    display: 'inline-block',
    borderRadius: 3,
    padding: '5px 15px',
    marginBottom: 15
  },
  finishedText: {
    backgroundColor: '#b90b09',
    color: '#fff'
  },
  pendingText: {
    backgroundColor: '#ffd400',
    color: '#000'
  },
  timeValue: {
    fontWeight: 'bold',
    paddingLeft: 5
  }
});

const useStyles = makeStyles(styles);

const TimerComponent = ({
  pendingText, finishedText, timerValueDate
}) => {
  const classes = useStyles();

  const parsedTime = React.useMemo(() => {
    return moment(timerValueDate).diff(moment(), 'seconds');
  }, [timerValueDate]);

  const [timeLeft, { start }] = useCountDown(parsedTime * 1000, 1000);

  React.useEffect(() => {
    if (parsedTime) {
      start(parsedTime * 1000);
    }
  }, [start, parsedTime]);

  if (timeLeft === 0) {
    return (
      <Fade in={true}>
        <Typography
          className={
            classNames({
              [classes.wrapper]: true,
              [classes.finishedText]: true
            })
          }
        >
          {finishedText}
        </Typography>
      </Fade>
    );
  }

  const addZero = (value) => (value < 10 ? `0${value}` : value);

  const hours = addZero(Math.floor(timeLeft / 1000 / 60 / 60));
  const minutes = addZero(Math.floor(timeLeft / 1000 / 60));
  const minutesLeft = addZero(Math.floor(timeLeft / 1000 / 60) - Number(hours) * 60);
  const seconds = addZero(Math.floor(timeLeft / 1000) - Number(minutes) * 60);

  return (
    <Typography
      className={
        classNames({
          [classes.wrapper]: true,
          [classes.pendingText]: true
        })
      }
    >
      {pendingText}
      <span className={classes.timeValue}>
        {hours}
        :
        {minutesLeft}
        :
        {seconds}
      </span>
    </Typography>
  );
};

TimerComponent.propTypes = {
  timerValue: PropTypes.number,
  pendingText: PropTypes.string.isRequired,
  finishedText: PropTypes.string.isRequired,
  timerValueDate: PropTypes.string
};

TimerComponent.defaultProps = {
  timerValue: null,
  timerValueDate: null
};

const Timer = ({
  task,
  jsonSchema,
  steps,
  activeStep
}) => {
  const { taskTimer } = jsonSchema;

  const timerData = React.useMemo(() => {
    return evaluate(taskTimer || '', task?.document?.data) || {};
  }, [taskTimer, task]);

  const { timer, steps: stepsProps } = React.useMemo(() => {
    return timerData;
  }, [timerData]);

  if (!taskTimer || !timer) return null;

  if (stepsProps) {
    const activeStepName = steps[activeStep];

    const isStepTimer = stepsProps.includes(activeStepName);

    if (!isStepTimer) return null;
  }

  return (
    <TimerComponent
      pendingText={timerData?.pendingText}
      finishedText={timerData?.finishedText}
      timerValueDate={timerData?.timerValueDate}
    />
  );
};

Timer.propTypes = {
  task: PropTypes.object,
  jsonSchema: PropTypes.object,
  steps: PropTypes.array,
  activeStep: PropTypes.number.isRequired
};

Timer.defaultProps = {
  task: {},
  steps: [],
  jsonSchema: {}
};

export default Timer;
