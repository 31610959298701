import React from 'react';
import { translate } from 'react-translate';

import _ from 'lodash/fp';

import DataTable from 'components/DataTable';
import EmptyPage from 'components/EmptyPage';

import dataTableConnect from 'services/dataTable/connectWithOwnProps';
import dataTableAdapter from 'services/dataTable/adapter';

import dataTableSettings from 'modules/tasks/pages/TaskList/variables/dataTableSettings';

const TaskTable = (props) => {
    const {
        t,
        data,
        count,
        filters,
        actions: { load },
        handleItemClick
    } = props;

    const nameFiltered = !Object.keys(filters).includes('name');

    if (count === 0 && !filters.filtered && !filters.search && nameFiltered) {
        return (
            <EmptyPage
                title={t('EmptyListTitle')}
                description={t('EmptyListDescription')}
                id="task-empty-page"
            />
        );
    }

    const hightlightIds = (data || []).filter(({ meta: { isRead }, finished }) => !isRead && !finished).map(({ id }) => id);
    const settings = dataTableSettings({ t, actions: { load } });

    return (
        <DataTable
            onRowClick={handleItemClick}
            hightlight={hightlightIds}
            {..._.merge(settings, dataTableAdapter(props))}
        />
    );
};

const translated = translate('TaskListPage')(TaskTable);
export default dataTableConnect(translated);
