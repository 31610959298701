import React from 'react';
import { translate } from 'react-translate';

import Select from 'components/Select';
import endPoint from 'components/PartySelect/endPoint';
import useTable from 'services/dataTable/useTable';

import { objectArrayUnique } from 'helpers/arrayUnique';

const toOption = t => (option = {}) => {
    const { id: value, data: { code, name } = {} } = option;
    return { ...option, label: t('EDRPOUCode', { code, name }), value };
};

const PartySelect = ({ t, value, onChange }) => {
    const [data, setData] = React.useState();
    const { data: tableData, page, search, loading, actions } = useTable(endPoint, { rowsPerPage: 5, filters: { keyId: 3 } });

    React.useEffect(() => {
        if (!loading && !data) {
            actions.load();
        }
    });

    React.useEffect(() => {
        setData(objectArrayUnique([].concat(data, tableData).filter(Boolean), 'id'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData]);

    return (
        <Select
            page={page}
            value={value}
            options={data && data.map(toOption(t))}
            onChange={onChange}
            description={t('PartySelect')}
            isLoading={loading}
            onChangePage={actions.onChangePage}
            onInputChange={newSearch => actions.onSearchChange(newSearch, newSearch !== search)}
            usePagination={true}
            pagination={tableData && tableData.meta}
        />
    );
};

export default translate('Analytics')(PartySelect);