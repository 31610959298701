export default {
    '0.1-0.5': {
        B3: ({ types }) => types === 'Основний' ? '✓' : '',
        B4: ({ types }) => types === 'Уточнюючий' ? '✓' : '',
        B6: 'year',
        B7: ({ period }) => period === '1 квартал' ? '✓' : '',
        B8: ({ period }) => period === '2 квартал' ? '✓' : '',
        B9: ({ period }) => period === '3 квартал' ? '✓' : '',
        B10: ({ period }) => period === '4 квартал' ? '✓' : '',
        B12: 'partyName',
        B13: 'partyCode',
        B15: 'partyLocationCountry',
        B16: 'partyLocationIndex',
        B17: 'partyLocationRegion',
        B18: 'partyLocationDistrict',
        B19: 'partyLocationCity',
        B20: 'partyLocationStreet',
        B21: 'partyLocationBuilding',
        B22: 'partyLocationKorpus',
        B23: 'partyLocationApt',
        B25: 'partyIsLocationSame',
        B26: ({ partyIsLocationSame, partyLocationCountryFact}) => partyIsLocationSame === 'Так' ? '' : partyLocationCountryFact,
        B27: 'partyLocationIndexFact',
        B28: 'partyLocationRegionFact',
        B29: 'partyLocationDistrictFact',
        B30: 'partyLocationCityFact',
        B31: 'partyLocationStreetFact',
        B32: 'partyLocationBuildingFact',
        B33: 'partyLocationKorpusFact',
        B34: 'partyLocationAptFact',
        B36: 'headLastName',
        B37: 'headFirstName',
        B38: 'headMiddleName',
        B39: 'headIpn',
        B40: ({
            headDateOfBirthDay: day,
            headDateOfBirthMonth: month,
            headDateOfBirthYear: year
        }) => [day, month, year].join('.'),
        B42: 'headPassportSerie',
        B43: 'headPassportNumber',
        B44: 'headIdNumber',
        B45: ({ headPassportIssurer, headIdIssurer }) => headPassportIssurer || headIdIssurer,
        B46: 'headProxyDoc',
        B48: 'headLocationCountry',
        B49: 'headLocationIndex',
        B50: 'headLocationRegion',
        B51: 'headLocationDistrict',
        B52: ({ headLocationCity, headLocationForeignLocation }) => headLocationCity || headLocationForeignLocation,
        B53: 'headLocationStreet',
        B54: 'headLocationBuilding',
        B55: 'headLocationKorpus',
        B56: 'headLocationApt',
        B58: 'headIsLocationSame',
        B59: 'headLocationCountryFact',
        B63: ({ headIsLocationSame, headLocationForeignLocationFact }) => headIsLocationSame === 'Ні' ? headLocationForeignLocationFact : '',
        B69: 'quantityFirst',
        B70: 'quantitySecond',
        B71: 'quantityThird'
    }
};
