export default [{
    source: 'tablets1',
    sheetName: '0.6',
    startRow: 4,
    startCell: 1,
    columns: ['index', 'name', 'code']
}, {
    source: 'tablets2',
    sheetName: '0.7',
    startRow: 4,
    startCell: 1,
    columns: ['index', 'name', 'code']
}, {
    source: 'propertyObjects',
    sheetName: '1.1',
    startRow: 4,
    startCell: 1,
    columns: ['index', 'objects1', 'objects2', 'objects3', 'objects4', 'objects5', 'objects6', 'objects7', 'objects9', 'objects10', 'objects11']
}, {
    source: 'propertyMovables',
    sheetName: '1.2',
    startRow: 4,
    startCell: 1,
    columns: ['index', 'movables1', 'movables2', 'movables3', 'movables4', 'movables5', 'movables7']
}, {
    source: 'propertyTransport',
    sheetName: '1.3',
    startRow: 4,
    startCell: 1,
    columns: ['index', 'transport1', 'transport2', 'transport3', 'transport4', 'transport5', 'transport6', 'transport7', 'transport8', 'transport10', 'transport11', 'transport12']
}, {
    source: 'propertyPapers',
    sheetName: '1.4',
    startRow: 4,
    startCell: 1,
    columns: ['index', 'papers1', 'papers2', 'papers3', 'papers4', 'papers6', 'papers5']
}, {
    source: 'propertyMoney',
    sheetName: '1.5',
    startRow: 4,
    startCell: 1,
    columns: ['index', 'money1', 'money2', 'money3', 'money4', 'money5', 'money6', 'money7', 'money8']
}, {
    source: 'propertyNoMoney',
    sheetName: '1.6',
    startRow: 4,
    startCell: 1,
    columns: ['index', 'noMoney1', 'noMoney2', 'noMoney3', 'noMoney4', 'noMoney5', 'noMoney6']
}, {
    source: 'contributionConMoney',
    sheetName: '2.1',
    startRow: 4,
    startCell: 1,
    columns: ['index', 'conMoney1', 'conMoney2', 'conMoney3', 'conMoney4', 'conMoney5', 'conMoney6', 'conMoney7', 'conMoney8', 'conMoney9', 'conMoney10']
}, {
    source: 'contributionOtherCon',
    sheetName: '2.2',
    startRow: 4,
    startCell: 1,
    columns: ['index', 'other1', 'other2', 'other3', 'other4', 'other5', 'other6', 'other7', 'other8', 'other10']
}, {
    source: 'contributionCosts',
    sheetName: '2.3',
    startRow: 4,
    startCell: 1,
    columns: ['index', 'costs1', 'costs2', 'costs3', 'costs4', 'costs5', 'costs6', 'costs7', 'costs8']
}, {
    source: 'contributionOtherCosts',
    sheetName: '2.4',
    startRow: 4,
    startCell: 1,
    columns: ['index', 'otherCosts1', 'otherCosts2', 'otherCosts3', 'otherCosts4', 'otherCosts5', 'otherCosts6', 'otherCosts7', 'otherCosts8', 'otherCosts9', 'otherCosts10']
}, {
    source: 'paymentGov',
    sheetName: '3.1',
    startRow: 4,
    startCell: 1,
    columns: ['index', 'paymentGov1', 'paymentGov2', 'paymentGov3', 'paymentGov5', 'paymentGov6', 'paymentGov7', 'paymentGov8', 'paymentGov9', 'paymentGov10', 'paymentGov11', 'paymentGov13', 'paymentGov12']
}, {
    source: 'paymentOther',
    sheetName: '3.2',
    startRow: 3,
    startCell: 1,
    columns: ['index', 'other1', 'other2', 'other3', 'other4', 'other5', 'other6', 'other7', 'other8', 'other9', 'other10', 'other11', 'other12'],
    totalRow: {
        cellName: 'Усього використано коштів',
        value: 'paymentOtherSum'
    }
}, {
    source: ['paymentCostsPaymentReceive', 'paymentCostsPaymentReturn'],
    sheetName: '3.3',
    startRow: 5,
    startCell: 1,
    columns: ['index', 'costs1', 'costs2', 'costs3', 'costs4', 'costs5', 'costs6', 'costs14', 'costs7', 'costs8', 'costs9', 'costs10', 'costs11', 'costs12', 'costs13', 'costs15']
}, {
    source: ['paymentOtherCostsPaymentReceive', 'paymentOtherCostsPaymentReturn'],
    sheetName: '3.4',
    startRow: 5,
    startCell: 1,
    columns: ['index', 'otherCosts1', 'otherCosts2', 'otherCosts3', 'otherCosts4', 'otherCosts5', 'otherCosts13', 'otherCosts6', 'otherCosts7', 'otherCosts8', 'otherCosts9', 'otherCosts10', 'otherCosts11', 'otherCosts12', 'otherCosts14']
}, {
    source: 'obligate',
    sheetName: '4.1',
    startRow: 3,
    startCell: 1,
    columns: ['index', 'obligates1', 'obligates2', 'obligates3', 'obligates4', 'obligates5', 'obligates6', 'obligates7', 'obligates8', 'obligates9']
}];

// obligate
