import React from 'react';
import PropTypes from 'prop-types';
import objectPath from 'object-path';
import { useTranslate } from 'react-translate';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import { makeStyles } from '@mui/styles';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import ElementContainer from 'components/JsonSchema/components/ElementContainer';
import evaluate from 'helpers/evaluate';
import diff from 'helpers/diff';
import 'leaflet/dist/leaflet.css';

const styles = (theme) => ({
  mapContainer: {
    height: 500,
    [theme.breakpoints.down('sm')]: {
      height: 200
    }
  },
  popupTitle: {
    marginBottom: 5,
    paddingBottom: 0,
    '&>h2': {
      fontSize: 26,
      fontWeight: '600',
      lineHeight: '32px',
    },
  },
  popupHeadline: {
    fontSize: 16,
    lineHeight: '28px',
  },
  popupValue: {
    fontSize: 14,
    lineHeight: '24px',
    marginBottom: 15,
    color: '#6D727C',
  },
  dialogRoot: {
    zIndex: 9999999999999,
  }
});

const useStyles = makeStyles(styles);

const GeojsonMap = ({
  position,
  zoom = 10,
  maxZoom = 30,
  dataPath,
  rootDocument,
  fieldsToDisplay,
  onChange,
  value,
  description,
  sample,
  required,
  error,
  hidden,
  checkPlaceActive,
  disableLayer
}) => {
  const t = useTranslate('GeojsonMap');
  const classes = useStyles();

  const [popupContent, setPopupContent] = React.useState(null);
  const [mapKey, setMapKey] = React.useState(0);
  const geoRefs = React.useRef(null);
  const mapRefs = React.useRef(null);

  const fitBounds = React.useCallback(() => {
    if (!geoRefs?.current || !mapRefs?.current) return;
    if (mapRefs?.current.getZoom() > 10) return;
    // eslint-disable-next-line no-unused-expressions
    mapRefs?.current.fitBounds(geoRefs.current.getBounds());
    // eslint-disable-next-line no-unused-expressions
    mapRefs?.current.zoomOut(1);
  }, [geoRefs, mapRefs]);

  const geoJson = React.useMemo(() => {
    try {
      if (!dataPath) {
        return null;
      }

      const geoJsonData = evaluate(dataPath, rootDocument?.data);

      if (geoJsonData instanceof Error) {
        return objectPath.get(rootDocument.data, dataPath);
      }

      return geoJsonData;
    } catch {
      return null;
    }
  }, [dataPath, rootDocument]); 

  const onEachFeature = React.useCallback((feature, layer) => {
    const showDialog = () => {
      setPopupContent(feature.properties);
    };

    const layerDisable = evaluate(disableLayer, feature.properties, rootDocument.data);
    const layerSelected = !diff(feature?.properties, value);

    if (layerSelected) {
      layer.setStyle({
        opacity: 1,
        color: '#f88d81',
        fillOpacity: 0.2,
        fillColor: '#f88d81',
      });
    }

    if (layerDisable) {
      layer.setStyle({
        opacity: 0.5,
        color: 'rgba(0, 0, 0, 0.38)',
        fillOpacity: 0.5,
        fillColor: 'rgba(0, 0, 0, 0.38)',
      });
    } else {
      layer.on({
        click: showDialog
      });
    }

  }, [disableLayer, rootDocument, value]);

  const handleChose = React.useCallback(() => {
    onChange(popupContent);
    setPopupContent(null);
    setMapKey((prevKey) => prevKey + 1);
  }, [popupContent, onChange]);

  const handleDelete = React.useCallback(() => {
    onChange(null);
    setPopupContent(null);
  }, [onChange]);

  const renderFields = (popupContent) => {
    return fieldsToDisplay
      .filter((field) => popupContent?.[field])
      .map((field) => (
        <div key={field}>
          <Typography className={classes.popupHeadline}>
            {t(field)}
          </Typography>
          <Typography className={classes.popupValue}>
            {popupContent?.[field]}
          </Typography>
        </div>
      ));
  };

  const placeActive = React.useMemo(() => {
    const isActiveResult = evaluate(checkPlaceActive, popupContent, rootDocument.data);
    return isActiveResult;
  }, [checkPlaceActive, rootDocument, popupContent]);

  React.useEffect(() => {
    setTimeout(() => {
      fitBounds();
    }, 500);
  }, [fitBounds, geoJson]);

  if (hidden) {
    return null;
  }

  return (
    <ElementContainer
      description={description}
      sample={sample}
      required={required}
      error={error}
      bottomSample={true}
      maxWidth={'100%'}
    >
      <div className={classes.mapContainer}>
        <MapContainer
          ref={mapRefs}
          center={position}
          zoom={zoom}
          maxZoom={maxZoom}
          style={{ height: '100%', width: '100%' }}
          attributionControl={false}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            maxZoom={maxZoom}
          />
         
          {
            geoJson ? (
              <GeoJSON
                key={mapKey}
                ref={geoRefs}
                data={geoJson}
                onEachFeature={onEachFeature}
              />
            ) : null
          }
        </MapContainer>

        {popupContent ? (
          <Dialog
            open={true}
            fullWidth={true}
            maxWidth={'sm'}
            scroll={'body'}
            onClose={() => setPopupContent(null)}
            classes={{
              root: classes.dialogRoot
            }}
          >
            <DialogTitle className={classes.popupTitle}>
              {popupContent?.address}
            </DialogTitle>
            <DialogContent>
              {renderFields(popupContent)}
              {placeActive?.text ? (
                <Typography className={classes.popupHeadline}>
                  {placeActive?.text}
                </Typography>
              ) : null}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setPopupContent(null)}
                color="primary"
              >
                {t('Close')}
              </Button>
              {
                diff(popupContent, value) ? (
                  <>
                    {placeActive?.active ? (
                      <Button
                        onClick={handleChose}
                        color="primary"
                        variant="contained"
                      >
                        {t('Chose')}
                      </Button>
                    ) : null}
                  </>
                ) : (
                  <Button
                    onClick={handleDelete}
                    color="primary"
                    variant="outlined"
                  >
                    {t('Delete')}
                  </Button>
                )
              }
            </DialogActions>
          </Dialog>
        ) : null}
      </div>
    </ElementContainer>
  );
};


GeojsonMap.propTypes = {
  position: PropTypes.array,
  zoom: PropTypes.number,
  dataPath: PropTypes.string,
  maxZoom: PropTypes.number,
  fieldsToDisplay: PropTypes.array,
  onChange: PropTypes.func,
  description: PropTypes.string,
  sample: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  hidden: PropTypes.bool,
  checkPlaceActive: PropTypes.string,
  disableLayer: PropTypes.string
};

GeojsonMap.defaultProps = {
  position: [50.450001, 30.523333],
  zoom: 10,
  dataPath: null,
  maxZoom: 20,
  fieldsToDisplay: ['zone_type', 'rental_price', 'status', 'organizer', 'orgnzr_phone_number'],
  onChange: () => {},
  description: '',
  sample: '',
  required: false,
  error: '',
  hidden: false,
  checkPlaceActive: '() => { return { active: true }; }',
  disableLayer: '({ payment_status }) => { return payment_status === 1; }'
};

export default GeojsonMap;
