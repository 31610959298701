import React from 'react';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';

const styles = {
    root: {
        display: 'inline-flex',
        width: 25,
        height: 25,
        flex: 'none !important',
        marginBottom: 12,
        marginRight: 12
    },
    dense: {
        marginBottom: 6
    }
};

const ColorBox = ({ classes, color: backgroundColor, dense = false }) => (
    <div className={classNames(classes.root, { [classes.dense]: dense })} style={{ backgroundColor }} />
);

export default withStyles(styles)(ColorBox);
