import React from 'react';
import useRS from 'radioactive-state';
import { translate } from 'react-translate';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import ColorBox from 'modules/analytics/components/ColorBox';
import ReportRangeSelect from 'components/PartySelect/ReportRangeSelect';

let initialState = {};
let multipleInitialState = {};

const styles = {
    gridItem: {
        display: 'flex',
        alignItems: 'flex-end',
        '& > div': {
            flex: 1
        }
    },
    compareCheckbox: {
        marginLeft: 10,
        marginRight: 10
    }
};

const WithRange = ({ t, classes, busy, setBusy, AnalyticsComponent, multiple, canCompare = true, ...rest }) => {
    const state = useRS(multiple ? multipleInitialState : initialState);
    const saveState = () => {
        if (multiple) {
            multipleInitialState = state;
        } else {
            initialState = state;
        }
    };
    const { office, party } = rest;

    return (
        <>
            <Grid container spacing={2}>
                <Grid item lg={state.useCompare ? 10 : 6} md={12} xs={12} className={classes.gridItem}>
                    {state.useCompare && multiple ? <ColorBox color="#E5E833" dense={!multiple} /> : null}
                    <ReportRangeSelect
                        value={state.range}
                        setBusy={setBusy}
                        party={party}
                        office={office}
                        exclude={state.compare}
                        multiple={multiple}
                        onChange={(newRange) => {
                            state.range = newRange;
                            saveState();
                        }}
                    />

                    {canCompare ? (
                        <>
                            <FormControlLabel
                                className={classes.compareCheckbox}
                                control={
                                    <Checkbox
                                        disabled={busy}
                                        checked={!!state.useCompare}
                                        onChange={({ target: { checked } }) => {
                                            state.useCompare = checked;
                                            if (!checked) {
                                                state.compare = null;
                                            }
                                            saveState();
                                        }}
                                    />
                                }
                                label={t('Compare')}
                            />
                            {state.useCompare ? (
                                <>
                                    {multiple ? <ColorBox color="#73E6FF" dense={!multiple} /> : null}
                                    <ReportRangeSelect
                                        value={state.compare}
                                        party={party}
                                        office={office}
                                        exclude={state.range}
                                        multiple={multiple}
                                        onChange={(newCompare) => {
                                            state.compare = newCompare;
                                            saveState();
                                        }}
                                    />
                                </>
                            ) : null}
                        </>
                    ) : null}
                </Grid>
            </Grid>
            <AnalyticsComponent {...rest} t={t} busy={busy} setBusy={setBusy} range={state.range} compare={state.compare} />
        </>
    );
};

const styled = (AnalyticsComponent, options) => withStyles(styles)(props => <WithRange {...props} {...options} AnalyticsComponent={AnalyticsComponent} />)
export default (AnalyticsComponent, options) => translate('Analytics')(styled(AnalyticsComponent, options));
