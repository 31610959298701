import React from 'react';
import { Link } from '@mui/material';
import reportToXlsx from './helpers/reportToXlsx';

const ExportToExcel = ({ report, reportIsCvk }) => {

    return (
        <Link
            href="#"
            style={{ marginLeft: 4 }}
            onClick={(e) => {
                e.preventDefault();
                reportToXlsx(report, reportIsCvk);
            }}
            underline="hover">
            XLSX
        </Link>
    );
};

export default ExportToExcel;