export default [
    {
        path: '',
        name: 'PartyInfo'
    }, {
        path: 'organizations',
        name: 'Organizatuions'
    }, {
        path: 'enterprises',
        name: 'Enterprises'
    }, {
        path: 'properties',
        name: 'Properties',
        children: [{
            path: 'real-estate',
            name: 'RealEstate'
        }, {
            path: 'movables',
            name: 'Movables'
        }, {
            path: 'transport',
            name: 'Transport'
        }, {
            path: 'securities',
            name: 'Securities'
        }, {
            path: 'money',
            name: 'Money'
        }, {
            path: 'intangible-assets',
            name: 'IntangibleAssets'
        }]
    }, {
        path: 'contributions',
        name: 'Contributions',
        children: [{
            path: 'con-money',
            name: 'ConMoney'
        }, {
            path: 'other-con',
            name: 'OtherCon'
        }, {
            path: 'coasts',
            name: 'Coasts'
        }, {
            path: 'other',
            name: 'Other'
        }]
    }, {
        path: 'payments',
        name: 'Payments',
        children: [{
            path: 'paymentGov',
            name: 'paymentGovName'
        }, {
            path: 'other',
            name: 'OtherName'
        }, {
            path: 'coasts',
            name: 'CoastsName'
        }, {
            path: 'otherCosts',
            name: 'OtherCostsName'
        }]
    }, {
        path: 'financial-liabilities',
        name: 'FinancialLiabilities'
    }, {
        path: 'risky-operations',
        name: 'RiskyOperations'
    }
];
