import React from 'react';
import { Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = {
    root: {
        padding: 10
    }
};

const Text = ({ classes, part: { value } = {} } = {}) => (
    <Typography className={classes.root} variant="body1">{value}</Typography>
);

export default withStyles(styles)(Text);
