import React from 'react';

import DataTable from 'components/DataTable';

const controls = {
    pagination: false,
    toolbar: false,
    search: false,
    header: true,
    refresh: false,
    switchView: false
};

const columnMap = columns => columns.map(columnName => ({ id: columnName, name: columnName }));

const dataMap = (items, columns) => items.map(row => columns.reduce((acc, columnName, index) => ({ ...acc, [columnName]: row[index] }), {}));

const Table = ({ part: { columns, items } = {} } = {}) => (
    <DataTable
        controls={controls}
        columns={columnMap(columns)}
        data={dataMap(items, columns)}
    />
);

export default Table;
