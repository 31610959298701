const calcTriggers = ({ stepName, hidden, isArray }) => {
    const commonTriggers = [
        {
            'source': `${stepName}.building.index`,
            'target': `${stepName}.apt.index`,
            'calculate': '(val) => val',
        },
        {
            'source': `${stepName}.apt.index`,
            'target': `${stepName}.building.index`,
            'calculate': '(val) => val',
        },
    ];

    if (isArray) {
        return [
            ...commonTriggers,
            {
                'source': `${stepName}`,
                'target': `${stepName}.hidden`,
                'calculate': `() => '${hidden}'`,
            }
        ];
    } else {
        return [
            ...commonTriggers,
            {
                'source': `${stepName}.ATU`,
                'target': `${stepName}.street`,
                'calculate': '() => undefined',
            },
            {
                'source': `${stepName}.region`,
                'target': `${stepName}.district`,
                'calculate': '() => undefined',
            },
            {
                'source': `${stepName}.region`,
                'target': `${stepName}.city`,
                'calculate': '() => undefined',
            },
            {
                'source': `${stepName}.region`,
                'target': `${stepName}.street`,
                'calculate': '() => undefined',
            },
            {
                'source': `${stepName}.district`,
                'target': `${stepName}.city`,
                'calculate': '() => undefined',
            },
            {
                'source': `${stepName}.district`,
                'target': `${stepName}.city`,
                'calculate': '() => undefined',
            },
        ];
    }
};

const defaultSchema = ({ stepName, withNamedObjects, recordsTree, hidden, isArray }) => {
    let atu = {};
    let streetControl = {};
    const path = isArray ? 'data.' : `data.${stepName}.`;
    const listenedValuesForStreet = isArray ? '' : `${stepName}.`;
    const stepDetails = isArray ? 'allStepsData' : 'pageObject';
    const checkRequired = isArray 
        ? `(a, b, c, parentValue) => { return parentValue?.hidden ? parentValue?.hidden !== 'true' : !${hidden}}` 
        : `() => !${hidden}`;
    const checkHidden = isArray 
        ? `(a, b, parentValue) => { return parentValue?.hidden ? parentValue?.hidden !== 'false' : ${hidden}}` 
        : `() => ${hidden}`;
    const checkApt = `(a, b, c, parentValue) => { const house = !(parentValue && parentValue.isPrivateHouse && parentValue.isPrivateHouse[0] === 'приватний будинок'); if (!house) {return false} if (parentValue?.hidden) {return parentValue?.hidden !== 'true'}  return !${hidden}}`;

    switch (recordsTree) {
        case false: {
            atu = {
                'region': {
                    'type': 'object',
                    'control': 'register',
                    'keyId': 408,
                    'description': 'Область або м. Київ',
                    'checkRequired': checkRequired,
                    'filtersFromSchema': true,
                    'hidden': checkHidden,
                    'indexedSort': {
                        'sort[data.name]': 'asc',
                    },
                },
                'district': {
                    'type': 'object',
                    'control': 'register',
                    'keyId': 410,
                    'description': 'Населений пункт або район',
                    'checkRequired': checkRequired,
                    'filtersFromSchema': true,
                    'cleanWhenHidden': true,
                    'address': true,
                    'hidden': checkHidden || `(document, value, parentValue) => { const region = parentValue.region; return !(region && region.atuId) }`,
                    'listenedValuesForRequest': [
                        isArray ? 'region' : `${stepName}.region`
                    ],
                    'indexedSort': {
                        'sort[data.name]': 'asc',
                    },
                    'search': `(data) => { return ${path}region.atuId  || 'unknown'; }`,
                },
                'city': {
                    'control': 'register',
                    'keyId': 411,
                    'description': 'Село, селище, район міста',
                    'checkRequired': checkRequired,
                    'markWhenEmpty': true,
                    'filtersFromSchema': true,
                    'cleanWhenHidden': true,
                    'address': true,
                    'hidden': checkHidden || `(document, value, parentValue) =>{return (!parentValue?.region && !parentValue?.district)}`,
                    'listenedValuesForRequest': [
                        isArray ? 'district' : `${stepName}.district`
                    ],
                    'indexedSort': {
                        'sort[data.type]': 'asc'
                    },
                    'search': `(data) => { return (${path}district && ${path}district.atuId) || 'unknown'; }`
                },
            };
            break;
        }
        case true: {
            atu = {
                'ATU': {
                    'type': 'object',
                    'control': 'register',
                    'cleanWhenHidden': true,
                    'allVisibleRequired': true,
                    'hidden': hidden,
                    'properties': {
                        'region': {
                            'keyId': 408,
                            'description': 'Область або м. Київ'
                        },
                        'district': {
                            'keyId': 410,
                            'description': 'Місто або район'
                        },
                        'city': {
                            'keyId': 411,
                            'description': 'Село, селище'
                        }
                    },
                    'required': []
                }
            };
            break;
        }
        default: {
            break;
        }
    }

    const recordsTreeStreetSearch = `(data) => {
        const regionId = ${path}ATU?.region?.atuId;
        const districtId = ${path}ATU?.district?.atuId;
        const cityId = ${path}ATU?.city?.atuId;
        return [regionId, districtId, cityId].filter(Boolean)  || 'unknown';
    }`;

    const streetSearch = `(data) => {
        const regionId = ${path}region?.atuId;
        const districtId = ${path}district?.atuId;
        const cityId = ${path}city?.atuId;
        return [regionId, districtId, cityId].filter(Boolean)  || 'unknown';
    }`;

    const recordsTreeListened = [
        `${listenedValuesForStreet}ATU.region`,
        `${listenedValuesForStreet}ATU.district`,
        `${listenedValuesForStreet}ATU.city`,
    ];

    const listenedValuesForRequest = [
        `${listenedValuesForStreet}region`,
        `${listenedValuesForStreet}district`,
        `${listenedValuesForStreet}city`,
    ];

    switch (withNamedObjects) {
        case null: {
            streetControl = {
                'control': 'form.group',
                'blockDisplay': false,
                'outlined': false,
                'hidden': hidden,
                'cleanWhenHidden': true,
                'properties': {
                    'streetType': {
                        'type': 'string',
                        'width': '60%',
                        'description': 'Тип вулиці',
                        'options': [
                            {
                                'id': 'вулиця',
                                'name': 'вулиця'
                            },
                            {
                                'id': 'провулок',
                                'name': 'провулок'
                            },
                            {
                                'id': 'площа',
                                'name': 'площа'
                            },
                            {
                                'id': 'проспект',
                                'name': 'проспект'
                            },
                            {
                                'id': 'бульвар',
                                'name': 'бульвар'
                            },
                            {
                                'id': 'тупік',
                                'name': 'тупік'
                            },
                            {
                                'id': 'узвіз',
                                'name': 'узвіз'
                            },
                            {
                                'id': 'набережна',
                                'name': 'набережна'
                            },
                            {
                                'id': 'шосе',
                                'name': 'шосе'
                            },
                            {
                                'id': 'мікрорайон',
                                'name': 'мікрорайон'
                            },
                            {
                                'id': 'житловий комплекс',
                                'name': 'житловий комплекс'
                            },
                            {
                                'id': 'жилий масив',
                                'name': 'жилий масив'
                            },
                            {
                                'id': 'проїзд',
                                'name': 'проїзд'
                            },
                            {
                                'id': 'майдан',
                                'name': 'майдан'
                            },
                            {
                                'id': 'квартал',
                                'name': 'квартал'
                            },
                            {
                                'id': "в'їзд",
                                'name': "в'їзд"
                            },
                            {
                                'id': 'інше',
                                'name': 'інше'
                            }
                        ]
                    },
                    'streetName': {
                        'type': 'string',
                        'description': 'Назва вулиці',
                        'checkValid': [
                            {
                                'isValid': '(propertyValue, stepValue, documentValue) => /^[-\'‘’" /А-ЩЬЮЯҐЄІЇа-щьюяґєії0-9]+$/.test(propertyValue)',
                                'errorText': 'Може містити тільки українські літери, цифри, дефіс, пробіл та лапки'
                            },
                            {
                                'isValid': "(propertyValue, stepValue, documentValue) => propertyValue && propertyValue !== '-' && propertyValue !== '–' && propertyValue !== '—' && propertyValue !== '\\'' &&  propertyValue !== ' '",
                                'errorText': 'Має містити букви'
                            },
                            {
                                'isValid': '(propertyValue, stepValue, documentValue) => propertyValue && !/[-]{2,}/.test(propertyValue) && !/[ ]{2,}/.test(propertyValue) ',
                                'errorText': 'Не може містити більше одного дефісу чи пробілу підряд'
                            }
                        ],
                        'checkRequired': checkRequired
                    }
                },
                'required': [
                    'streetType', 
                    'streetName'
                ]
            };
            break;
        }
        case true: {
            streetControl = {
                'type': 'object',
                'control': 'register',
                'keyId': 450,
                'description': 'Назва вулиці',
                'filtersType': 'or',
                'autocomplete': true,
                'autocompleteField': 'name',
                'cleanWhenHidden': true,
                'markWhenEmpty': true,
                'filtersFromSchema': true,
                'checkRequired': checkRequired,
                'hidden': checkHidden,
                'listenedValuesForRequest': recordsTree ? recordsTreeListened : listenedValuesForRequest,
                'indexedSort': {
                    'sort[data.name]': 'asc'
                },
                'search': recordsTree ? recordsTreeStreetSearch : streetSearch
            };
            break;
        }
        case false: {
            streetControl = {
                'type': 'object',
                'control': 'register',
                'keyId': 412,
                'description': 'Назва вулиці',
                'filtersType': 'or',
                'autocomplete': true,
                'autocompleteField': 'name',
                'cleanWhenHidden': true,
                'markWhenEmpty': true,
                'filtersFromSchema': true,
                'hidden': checkHidden || '() => false',
                'checkRequired': checkRequired,
                'listenedValuesForRequest': recordsTree ? recordsTreeListened : listenedValuesForRequest,
                'indexedSort': {
                    'sort[data.name]': 'asc'
                },
                'search': recordsTree ? recordsTreeStreetSearch : streetSearch
            };
            break;
        }
        default: {
            break;
        }
    }

    return {
        'type': 'object',
        'properties': {
            ...atu,
            'street': streetControl,
            'building': {
                'control': 'form.group',
                'blockDisplay': false,
                'outlined': false,
                'hidden': hidden,
                'useOwnData': true,
                'properties': {
                    'building': {
                        'type': 'string',
                        'checkRequired': checkRequired,
                        'description': 'Будинок',
                        'width': '50%',
                        'checkValid': [
                            {
                                'isValid': '(propertyValue, stepValue, documentValue) => propertyValue && propertyValue.length <= 10',
                                'errorText': 'Може містити не більше, ніж 10 символів'
                            },
                            {
                                'isValid': '(propertyValue, stepValue, documentValue) => propertyValue && !/[-]{2,}/.test(propertyValue) && !/[ ]{2,}/.test(propertyValue) ',
                                'errorText': 'Не може містити більше одного дефісу чи пробілу підряд'
                            }
                        ]
                    },
                    'korpus': {
                        'type': 'string',
                        'description': 'Корпус',
                        'width': '50%',
                        'cleanWhenHidden': true,
                        'checkValid': [
                            {
                                'isValid': '(propertyValue, stepValue, documentValue) => propertyValue && propertyValue.length <= 10',
                                'errorText': 'Може містити не більше, ніж 10 символів'
                            },
                            {
                                'isValid': '(propertyValue, stepValue, documentValue) => propertyValue && !/[-]{2,}/.test(propertyValue) && !/[ ]{2,}/.test(propertyValue) ',
                                'errorText': 'Не може містити більше одного дефісу чи пробілу підряд'
                            }
                        ],
                        'checkHidden': `(propertyData, pageObject, allStepsData) => { return (${stepDetails} && ${stepDetails}.isPrivateHouse && ${stepDetails}.isPrivateHouse[0] === 'приватний будинок')}`,
                    },
                    'index': {
                        'type': 'string',
                        'description': 'Індекс',
                        'checkRequired': `(propertyData, pageObject, allStepsData) => { if (${hidden}) { return false; }  return (pageObject && pageObject.isPrivateHouse && pageObject.isPrivateHouse[0] === 'приватний будинок'); }`,
                        'sample': "<div style='display: inline-flex; background: #FFF4D7; padding: 10px 15px 10px 15px'>Дізнатися свій індекс можна&nbsp;<a href='https://ukrposhta.ua/dovidka/indeksi/' target='_blank' style='color:#000000;'>тут</a></div></div>",
                        'width': '50%',
                        'checkValid': [
                            {
                                'isValid': '(propertyValue, stepValue, documentValue) => propertyValue && propertyValue.length === 5',
                                'errorText': 'Має містити 5 цифр'
                            }
                        ],
                        'mask': '99999',
                        'checkHidden': `(propertyData, pageObject, allStepsData) => { return  !(${stepDetails} && ${stepDetails}.isPrivateHouse && ${stepDetails}.isPrivateHouse[0] === 'приватний будинок')}`
                    }
                },
                'required': []
            },
            'isPrivateHouse': {
                'type': 'array',
                'control': 'checkbox.group',
                'width': '50%',
                'secondary': true,
                'withIndex': false,
                'hidden': hidden,
                'items': [
                    {
                        'id': 'приватний будинок',
                        'title': 'приватний будинок'
                    }
                ],
                'rowDirection': true
            },
            'apt': {
                'control': 'form.group',
                'blockDisplay': false,
                'outlined': false,
                'hidden': hidden,
                'useOwnData': true,
                'properties': {
                    'apt': {
                        'type': 'string',
                        'description': 'Номер квартири',
                        'cleanWhenHidden': true,
                        'checkValid': [
                            {
                                'isValid': '(propertyValue, stepValue, documentValue) => propertyValue && propertyValue.length <= 10',
                                'errorText': 'Може містити не більше, ніж 10 символів'
                            },
                            {
                                'isValid': '(propertyValue, stepValue, documentValue) => propertyValue && !/[-]{2,}/.test(propertyValue) && !/[ ]{2,}/.test(propertyValue) ',
                                'errorText': 'Не може містити більше одного дефісу чи пробілу підряд'
                            }
                        ],
                        'checkHidden': `(propertyData, pageObject, allStepsData) => { return (${stepDetails} && ${stepDetails}.isPrivateHouse && ${stepDetails}.isPrivateHouse[0] === 'приватний будинок')}`,
                        'checkRequired': isArray ? checkApt : `(propertyData, pageObject, allStepsData, parentValue) => { if (${hidden}) { return false; } return !(parentValue && parentValue.isPrivateHouse && parentValue.isPrivateHouse[0] === 'приватний будинок')}`
                    },
                    'index': {
                        'type': 'string',
                        'description': 'Індекс',
                        'checkRequired': isArray ? checkRequired : `(value, pageObject, allStepsData, parentValue) => { if (${hidden}) { return false; } return !parentValue?.isPrivateHouse || parentValue?.isPrivateHouse[0] !== 'приватний будинок';}`,
                        'sample': "<div style='display: inline-flex; background: #FFF4D7; padding: 10px 15px 10px 15px'>Дізнатися свій індекс можна&nbsp;<a href='https://ukrposhta.ua/dovidka/indeksi/' target='_blank' style='color:#000000;'>тут</a></div></div>",
                        'checkValid': [
                            {
                                'isValid': '(propertyValue, stepValue, documentValue) => propertyValue && propertyValue.length === 5',
                                'errorText': 'Має містити 5 цифр'
                            }
                        ],
                        'mask': '99999',
                        'checkHidden': `(propertyData, pageObject, allStepsData) => { return (${stepDetails} && ${stepDetails}.isPrivateHouse && ${stepDetails}.isPrivateHouse[0] === 'приватний будинок')}`
                    }
                },
                'required': []
            }
        }
    }
};

export { calcTriggers, defaultSchema };
