import React from 'react';
import useRS from 'radioactive-state';
import { translate } from 'react-translate';
import { Grid } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import LeftSidebarLayout, { Content } from 'layouts/LeftSidebar';

import PartySelect from 'components/PartySelect';
import OfficeSelect from 'components/PartySelect/OfficeSelect';

import AnalyticsMenu from 'modules/analytics/components/AnalyticsMenu';

let initialState = {};

const styles = {
    content: {
        paddingTop: 10
    },
    menu: {
        marginTop: 20,
        marginBottom: 20
    },
    gridItem: {
        display: 'flex',
        alignItems: 'flex-end',
        '& > div': {
            flex: 1
        }
    }
};

const AnalyticsPage = ({ AnalyticsComponent, t, classes, location, title, loading, path, ...rest }) => {
    const state = useRS(initialState);

    const saveState = () => {
        initialState = state;
    };

    React.useEffect(() => {
        state.busy = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AnalyticsComponent])

    return (
        <LeftSidebarLayout location={location} title={t(title)} loading={loading}>
            <Content className={classes.content}>
                <Grid container spacing={2}>
                    <Grid item lg={5} md={6} xs={12} className={classes.gridItem}>
                        <PartySelect
                            value={state.party}
                            onChange={selectedParty => {
                                state.party = selectedParty;
                                saveState();
                            }}
                        />
                    </Grid>
                    <Grid item lg={5} md={6} xs={12} className={classes.gridItem}>
                        <OfficeSelect
                            busy={!!state.busy}
                            value={state.office}
                            party={state.party}
                            onChange={office => {
                                state.office = office;
                                saveState();
                            }}
                        />
                    </Grid>
                </Grid>
                <div className={classes.menu}>
                    <AnalyticsMenu
                        rootPath="/analytics"
                        path={(path + '/').split('/').slice(2)}
                    />
                </div>
                <AnalyticsComponent
                    {...rest}
                    {...state}
                    setBusy={busy => {
                        state.busy = busy;
                    }}
                />
            </Content>
        </LeftSidebarLayout>
    );
};

const styled = AnalyticsComponent => withStyles(styles)(props => <AnalyticsPage {...props} AnalyticsComponent={AnalyticsComponent} />);
export default AnalyticsComponent => translate('Analytics')(styled(AnalyticsComponent));