import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import { Button, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { Link } from 'react-router-dom';

import evaluate from 'helpers/evaluate';
import renderHTML from 'helpers/renderHTML';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import NavigateNextIcon from '@mui/icons-material/NavigateNextRounded';

const styles = {
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    wrapper: {
        flexGrow: 1,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    icon: {
        fontSize: 82,
        color: 'green'
    },
    title: {
        textAlign: 'center'
    },
    button: {
        textDecoration: 'none'
    },
    action: {
        marginTop: 40
    },
    actionButton: {
        marginRight: 10
    }
};

const SuccessMessageLayout = ({ t, classes, finalScreen, nextTasks, rootPath, task, showNextTaskButton }) => {
    const nextTask = (nextTasks || []).shift();

    const getTitle = () => {
        const evaluatedTitle = evaluate((finalScreen || {}).title, task && task.document && task.document.data);

        if (!(evaluatedTitle instanceof Error)) {
            return renderHTML(evaluatedTitle);
        }

        return false;
    };

    const getSubtitle = () => {
        const evaluatedTitle = evaluate((finalScreen || {}).subtitle, task && task.document && task.document.data);

        if (!(evaluatedTitle instanceof Error)) {
            return renderHTML(evaluatedTitle);
        }

        return false;
    };

    const getNextButtonTitle = () => {
        const { nextTaskButtonName } = finalScreen || {};

        if (!nextTaskButtonName) {
            return t('GoToNextTask');
        }

        const evaluatedTitle = evaluate((finalScreen || {}).nextTaskButtonName, task?.document?.data);

        if (evaluatedTitle instanceof Error) {
            return nextTaskButtonName;
        }

        return evaluatedTitle;
    };

    return (
        <div className={classes.root}>
            <div className={classes.wrapper} />
            <div className={classes.wrapper}>
                <CheckRoundedIcon className={classes.icon} />
                <Typography variant="h6">{getTitle() || finalScreen.title || t('SuccessCommitMessageHeader')}</Typography>
                <Typography variant="body1">{getSubtitle() || finalScreen.subtitle || t('SuccessCommitMessageText')}</Typography>

                {
                    nextTask && showNextTaskButton ? (
                        <div className={classes.action}>
                            <Link to={`${rootPath}/${nextTask.id}`} className={classes.button}>
                                <Button variant="contained" color="primary" size="large">
                                    {getNextButtonTitle()}
                                    <NavigateNextIcon />
                                </Button>
                            </Link>
                        </div>
                    ) : null
                }

                {
                    Array.isArray(finalScreen.actions) ? finalScreen.actions.map(({ title, link, variant, color }, key) => (
                        <div className={classes.action} key={key}>
                            <a href={link} className={classes.button}>
                                <Button variant={variant} color={color} size="large" className={classes.actionButton}>
                                    {title}
                                </Button>
                            </a>
                        </div>
                    )) : null
                }
            </div>
            <div className={classes.wrapper} />
        </div>
    );
};

SuccessMessageLayout.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    finalScreen: PropTypes.object,
    nextTasks: PropTypes.array,
    showNextTaskButton: PropTypes.bool
};

SuccessMessageLayout.defaultProps = {
    finalScreen: {},
    nextTasks: null,
    showNextTaskButton: true
};

const translated = translate('TaskPage')(SuccessMessageLayout);
export default withStyles(styles)(translated);
