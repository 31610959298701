import React from 'react';
import { translate } from 'react-translate';
import printJS from 'print-js';
import PropTypes from 'prop-types';
import {
    Tooltip,
    IconButton
} from '@mui/material';
import { humanDateTimeFormat } from 'helpers/humanDateFormat';
import KeyIcon from '@mui/icons-material/VpnKey';

class SignatureDetails extends React.Component {
    print = () => {
        const {
            t,
            item: {
                signature: {
                    signer: {
                        commonName
                    },
                    issuer,
                    signTime
                },
                fileName,
                documentId
            }
        } = this.props;

        const printData = [
            {
                info: t('Info')
            },
            {
                info: t('FileTitle') + ' ' + fileName
            },
            {
                info: t('Person') + ' ' + commonName
            },
            {
                info: t('DateTime') + ' ' + humanDateTimeFormat(signTime)
            },
            {
                info: t('ACSK') + ' ' + issuer.commonName
            },
            {
                info: t('DocumentId') + ' ' + documentId
            }
        ];

        printJS({
            printable: printData,
            properties: ['info'],
            type: 'json',
            gridHeaderStyle: 'opacity: 0;',
            gridStyle: 'border: none;',
            documentTitle: ''
        });
    };

    render = () => {
        const { t } = this.props;

        return <>
            <Tooltip title={t('Signature')}>
                <IconButton onClick={this.print} size="large">
                    <KeyIcon />
                </IconButton>
            </Tooltip>
        </>;
    }
}

SignatureDetails.propTypes = {
    t: PropTypes.func.isRequired,
    signature: PropTypes.object.isRequired
};

SignatureDetails.defaultProps = {};

export default translate('FileDataTable')(SignatureDetails);
