import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-translate';

import { Typography, Toolbar, IconButton, Divider } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ListIcon from '@mui/icons-material/List';

import { Content } from 'layouts/LeftSidebar';

import PerformerUserSelect from './PerformerUserSelect';
import PerformerUserList from './PerformerUserList';

const styles = {
    flexGrow: {
        flexGrow: 1
    },
    inlineWrapper: {
        marginTop: 5
    }
};

const TaskAssign = ({ t, classes, task, task: { performerUnits, finished }, userUnits, isInline }) => {
    const [edit, setEdit] = React.useState(false);

    const userHeadUnitIds = userUnits.filter(({ head }) => head).map(({ id }) => id);
    const units = performerUnits.filter(unitId => userHeadUnitIds.includes(unitId));
    const canEdit = !!units.length && !finished;

    const body = (
        <>
            <Toolbar disableGutters={true}>
                <Typography variant="body2">{t('TaskAssign')}</Typography>
                <div className={classes.flexGrow} />
                {
                    canEdit ? (
                        <IconButton onClick={() => setEdit(!edit)} size="large">
                            {edit ? <ListIcon /> : <EditOutlinedIcon />}
                        </IconButton>
                    ) : null
                }
            </Toolbar>
            <Divider light={true} />
            {
                edit
                ? <PerformerUserSelect task={task} setEdit={setEdit} />
                : <PerformerUserList task={task} setEdit={setEdit} />
            }
        </>
    );

    return (
        <>
            {
                isInline ? (
                    <>
                        <div className={classes.inlineWrapper}>
                            {body}
                        </div>
                        <Divider light={true} />
                    </>
                ) : (
                    <Content small={true}>
                        <Divider light={true} />
                        {body}
                        <Divider light={true} />
                    </Content>
                )
            }
        </>
    );
};

const mapState = ({ auth: { userUnits } }) => ({ userUnits });

const styled = withStyles(styles)(TaskAssign);
const translated = translate('TaskPage')(styled);
export default connect(mapState)(translated);
