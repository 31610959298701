import React from 'react';
import PropTypes from 'prop-types';

import { Paper, FormControlLabel, Checkbox } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { translate } from 'react-translate';

import LeftSidebarLayout from 'layouts/LeftSidebar';

import MessageTable from './MessageTable';

const styles = {
    toolbar: {
        paddingLeft: 12
    }
};

const MessageListLayout = ({ t, classes, actions, location, filters, title, loading, data, handleItemClick }) => (
    <LeftSidebarLayout
        location={location}
        title={title}
        loading={loading}
    >
        <Paper square variant="outlined" className={classes.toolbar}>
            <FormControlLabel
                control={(
                    <Checkbox
                        checked={!!(filters && filters.is_read === 0)}
                        onChange={
                            ({ target: { checked } }) => actions.onFilterChange({
                                ...filters,
                                is_read: checked ? 0 : undefined
                            })
                        }
                        name="checkedB"
                        color="primary"
                    />
                )}
                label={t('UnreadOnly')}
            />
        </Paper>
        <MessageTable handleItemClick={handleItemClick} />
    </LeftSidebarLayout>
);

MessageListLayout.propTypes = {
    data: PropTypes.array,
    location: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    handleItemClick: PropTypes.func
};

MessageListLayout.defaultProps = {
    data: null,
    loading: false,
    handleItemClick: () => null
};

const styled = withStyles(styles)(MessageListLayout);
export default translate('MessageListPage')(styled);
