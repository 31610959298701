import React from 'react';
import { translate } from 'react-translate';
import config from 'config';
import _ from 'lodash/fp';

import DataTable from 'components/DataTable';
import EmptyPage from 'components/EmptyPage';

import dataTableConnect from 'services/dataTable/connectWithOwnProps';
import dataTableAdapter from 'services/dataTable/adapter';

import CreateTaskButton from 'modules/tasks/components/CreateTaskButton';
import dataTableSettings from 'modules/workflow/pages/WorkflowList/variables/dataTableSettings';

import currentText from 'modules/workflow/pages/WorkflowList/components/currentText';

const { application: { name } } = config;

const WorkflowTable = (props) => {
    const {
        t,
        count,
        filters,
        checkable,
        loading,
        actions: { load },
        handleItemClick,
        TableToolbar,
        endPoint
    } = props;

    const nameFiltered = !Object.keys(filters).includes('name');

    if (count === 0 && !filters.filtered && !filters.search && !loading && nameFiltered) {
        const text = currentText(filters);
        return (
            <EmptyPage
                title={t(text.title)}
                description={t(text.description)}
            >
                {name !== 'POLITDATA' ? <CreateTaskButton /> : null}
            </EmptyPage>
        );
    }

    const settings = dataTableSettings({ t, filters, actions: { load } });

    return (
        <DataTable
            {..._.merge(settings, dataTableAdapter(props, endPoint))}
            checkable={checkable}
            onRowClick={handleItemClick}
            CustomToolbar={TableToolbar}
        />
    );
};

const translated = translate('WorkflowListPage')(WorkflowTable);
export default dataTableConnect(translated);
