import React from 'react';
import { translate } from 'react-translate';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Fab } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';


const styles = theme => ({
    link: {
        textDecoration: 'none'
    },
    fab: {
        width: '100%!important',
        borderRadius: '3px!important',
        color: theme.textColorDark,
        backgroundColor: theme.buttonBg,
        minHeight: '50px',
        marginBottom: '26px',
        marginTop: '26px',
        '&:hover': {
            backgroundColor: theme.buttonHoverBg
        }
    },
    bottomFab: {
        marginTop: 10,
        marginBottom: 26
    }
});

const CreateTaskButton = ({ t, classes }) => (
    <>
        <Link
            to={'/tasks/create/101118/101118001'}
            className={classes.link}
        >
            <Fab
                size="medium"
                variant="extended"
                aria-label="Add"
                className={classNames(classes.fab, classes.bottomFab)}
            >
                {t('AddCandidateReport')}
            </Fab>
        </Link>
    </>
);

const mapState = ({ auth: { userUnits } }) => ({ userUnits });

const styled = withStyles(styles)(CreateTaskButton);

const translated = translate('Navigator')(styled);

export default connect(mapState)(translated);

